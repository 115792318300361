import Cookies from "js-cookie";
import { Component } from "react";
import styles from "./index.module.css";
import apiClient from "../../../utils/apiUrls/apiClient";
import SideBar from "../../layouts/SideBar";
import CampusNavBar from "../../layouts/CampusNavBar";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import "../../LoginForm/index.css";
import NavLink from "../NavLink";

class ModifyWeightages extends Component {
  state = {
    activeTab: 1,
    coCreated: false,
    poCreated: false,
    batch: "ALL",
    branch: "ALL",
    regulation: "ALL",
    semester: "ALL",
    batchOptions: [],
    branchOptions: [],
    semOptions: [],
    regOptions: [],
    coLoading: false,
    poLoading: false,
    intWeight: "",
    extWeight: "",
    directCoW: "",
    indirectCoW: "",
    poBranch: "ALL",
    poBatch: "ALL",
    poRegulation: "ALL",
    directPoW: "",
    indirectPoW: "",
    lvl1Range: "",
    lvl2Range: "",
    lvl3Range: "",
    poTarget: "",
    claims: {},
  };

  updateChanges = async (event) => {
    event.preventDefault();

    const {
      batch,
      regulation,
      branch,
      semester,
      intWeight,
      extWeight,
      directCoW,
      indirectCoW,
      claims,
    } = this.state;
    try {
      const token = Cookies.get("jwt_token");
      if (parseFloat(intWeight) + parseFloat(extWeight) === parseFloat(100)) {
        if (
          parseFloat(directCoW) + parseFloat(indirectCoW) ===
          parseFloat(100)
        ) {
          this.setState({ coLoading: true });

          const reqBody = {
            cieW: intWeight,
            directCoW: directCoW,
            indirectCoW: indirectCoW,
            seeW: extWeight,
          };

          const options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reqBody),
          };

          const response = await fetch(
            apiClient.urls.fatcat.UPDATE_CO_WEIGHTAGES +
              `?batchId=${batch}&branch=${
                claims["user_type"] === "HOD" ? claims["dept_name"] : branch
              }&regulationId=${regulation}&semester=${semester}`,
            options
          );

          if (response.ok === true) {
            const res = await response.json();
            if (res["statusCode"] === "OK") {
              this.setState({ coCreated: true });
            } else {
              alert("CO weightages are not updated");
            }
          } else {
            alert("CO weightages are not updated");
          }

          this.setState({ coLoading: false });
        } else {
          alert(
            "Sum of both Direct CO and Indirect CO weightages should be equal to 100"
          );
        }
      } else {
        alert(
          "Sum of both Internal and External weightages should be equal to 100"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  poUpdateChanges = async (event) => {
    event.preventDefault();

    const {
      poBatch,
      poRegulation,
      poBranch,
      lvl1Range,
      lvl2Range,
      lvl3Range,
      directPoW,
      indirectPoW,
      poTarget,
      claims,
    } = this.state;
    try {
      const token = Cookies.get("jwt_token");
      if (parseFloat(directPoW) + parseFloat(indirectPoW) === parseFloat(100)) {
        this.setState({ poLoading: true });

        const reqBody = {
          directPoW: directPoW,
          indirectPoW: indirectPoW,
          lvl1W: lvl1Range,
          lvl2W: lvl2Range,
          lvl3w: lvl3Range,
          poTargetAtt: poTarget,
        };

        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(reqBody),
        };

        const response = await fetch(
          apiClient.urls.fatcat.UPDATE_CO_WEIGHTAGES +
            `?batchId=${poBatch}&branch=${
              claims["user_type"] === "HOD" ? claims["dept_name"] : poBranch
            }&regulationId=${poRegulation}&semester=ALL`,
          options
        );

        if (response.ok === true) {
          const res = await response.json();
          if (res["statusCode"] === "OK") {
            this.setState({ poCreated: true });
          } else {
            alert("PO weightages and Levels are not updated");
          }
        } else {
          alert("PO weightages and Levels are not updated");
        }

        this.setState({ poLoading: false });
      } else {
        alert(
          "Sum of both Direct PO and Indirect PO weightages should be equal to 100"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    const token = Cookies.get("jwt_token");
    const { history } = this.props;
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      claim["user_type"] !== "PRINCIPAL" &&
        claim["user_type"] !== "HOD" &&
        history.replace("/");
      this.setState({ claims: claim });
    }
    this.getOptions();
  }

  coWeightages = () => {
    this.setState({ activeTab: 1 });
  };

  poWeightages = () => {
    this.setState({ activeTab: 2 });
  };

  getOptions = async () => {
    try {
      await this.getBranchOptions();
      await this.getSemOptions();
      await this.getRegOptions();
      await this.getBatchOptions();
    } catch (err) {
      console.log(err);
    }
  };

  getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ branchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ semOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ batchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getRegOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=regulation_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ regOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderBatchOptions = () => {
    const { batchOptions } = this.state;
    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { branchOptions } = this.state;

    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSemOptions = () => {
    const { semOptions } = this.state;

    const ans = [];
    try {
      for (const item of semOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderRegOptions = () => {
    const { regOptions } = this.state;

    const ans = [];
    try {
      for (const item of regOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  regulationChanged = (event) => {
    this.setState({ regulation: event.target.value });
  };

  batchChanged = (event) => {
    this.setState({ batch: event.target.value });
  };

  branchChanged = (event) => {
    this.setState({ branch: event.target.value });
  };

  semesterChanged = (event) => {
    this.setState({ semester: event.target.value });
  };

  intWeightChange = (event) => {
    this.setState({ intWeight: event.target.value });
  };

  extWeightChange = (event) => {
    this.setState({ extWeight: event.target.value });
  };

  directCoWChange = (event) => {
    this.setState({ directCoW: event.target.value });
  };

  indirectCoWChange = (event) => {
    this.setState({ indirectCoW: event.target.value });
  };

  regulationPoChanged = (event) => {
    this.setState({ poRegulation: event.target.value });
  };

  batchPoChanged = (event) => {
    this.setState({ poBatch: event.target.value });
  };

  branchPoChanged = (event) => {
    this.setState({ poBranch: event.target.value });
  };

  directPoWChange = (event) => {
    this.setState({ directPoW: event.target.value });
  };

  indirectPoWChange = (event) => {
    this.setState({ indirectPoW: event.target.value });
  };

  lvl1change = (event) => {
    this.setState({ lvl1Range: event.target.value });
  };

  lvl2change = (event) => {
    this.setState({ lvl2Range: event.target.value });
  };

  lvl3change = (event) => {
    this.setState({ lvl3Range: event.target.value });
  };

  poTargetChanged = (event) => {
    this.setState({ poTarget: event.target.value });
  };

  renderEditWeightagesView = () => {
    const {
      coCreated,
      coLoading,
      directCoW,
      indirectCoW,
      batch,
      branch,
      semester,
      regulation,
      intWeight,
      extWeight,
      claims,
    } = this.state;
    return (
      <>
        {coCreated ? (
          <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <img
              src="/assets/tick.svg"
              alt="new course"
              width={34}
              height={34}
            />
            <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
              CO Weightages Modified successfully!
            </h1>
            <button
              onClick={this.okBtn}
              className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
              type="button"
            >
              Ok
            </button>
          </div>
        ) : (
          <form onSubmit={this.updateChanges}>
            <div className="flex flex-row flex-wrap w-11/12 text-[#636363] mb-5 p-5">
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Regulation</label>
                <select
                  value={regulation}
                  onChange={this.regulationChanged}
                  required
                  className={`${styles["filter-select-wght"]} focus:ring-0 p-2`}
                >
                  <option value="ALL">ALL</option>

                  {this.renderRegOptions()}
                </select>
              </span>
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Batch</label>
                <select
                  value={batch}
                  onChange={this.batchChanged}
                  required
                  className={`${styles["filter-select-wght"]} focus:ring-0 p-2`}
                >
                  <option value="ALL">ALL</option>

                  {this.renderBatchOptions()}
                </select>
              </span>
              {claims["user_type"] !== "HOD" && (
                <span className="w-3/6 mt-1 flex flex-col">
                  <label className={styles["label"]}>Branch</label>
                  <select
                    required
                    value={branch}
                    onChange={this.branchChanged}
                    className={`${styles["filter-select-wght"]} focus:ring-0 p-2`}
                  >
                    <option value="ALL">ALL</option>

                    {this.renderBranchOptions()}
                  </select>
                </span>
              )}
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Semester</label>
                <select
                  value={semester}
                  onChange={this.semesterChanged}
                  required
                  className={`${styles["filter-select-wght"]} focus:ring-0 p-2`}
                >
                  <option value="ALL">ALL</option>
                  {this.renderSemOptions()}
                </select>
              </span>

              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>
                  Internal Weightage (%)
                </label>
                <input
                  value={intWeight}
                  onChange={this.intWeightChange}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>
                  External Weightage (%)
                </label>
                <input
                  value={extWeight}
                  onChange={this.extWeightChange}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>

              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}> Direct CO Weightage</label>
                <input
                  value={directCoW}
                  onChange={this.directCoWChange}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Indirect CO Weightage</label>
                <input
                  value={indirectCoW}
                  onChange={this.indirectCoWChange}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>
            </div>
            <div className="flex flex-row justify-end">
              <button
                disabled={coLoading}
                className={`${
                  coLoading && "cursor-not-allowed"
                } text-white bg-[#456BF1] rounded-lg text-md font-medium px-8 py-2 mr-5 mb-8 mt-4`}
                type="submit"
              >
                {coLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Update Changes"
                )}
              </button>
            </div>
          </form>
        )}
      </>
    );
  };

  renderPOView = () => {
    const {
      poCreated,
      poLoading,
      directPoW,
      indirectPoW,
      poBatch,
      poBranch,
      poRegulation,
      lvl1Range,
      lvl2Range,
      lvl3Range,
      poTarget,
      claims,
    } = this.state;
    return (
      <>
        {poCreated ? (
          <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <img
              src="/assets/tick.svg"
              alt="new course"
              width={34}
              height={34}
            />
            <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
              PO Weightages & Levels Modified successfully!
            </h1>
            <button
              onClick={this.okBtn}
              className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
              type="button"
            >
              Ok
            </button>
          </div>
        ) : (
          <form onSubmit={this.poUpdateChanges}>
            <div className="flex flex-row flex-wrap w-11/12 text-[#636363] mb-5 p-5">
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Regulation</label>
                <select
                  value={poRegulation}
                  onChange={this.regulationPoChanged}
                  required
                  className={`${styles["filter-select-wght"]} focus:ring-0 p-2`}
                >
                  <option value="ALL">ALL</option>

                  {this.renderRegOptions()}
                </select>
              </span>
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Batch</label>
                <select
                  value={poBatch}
                  onChange={this.batchPoChanged}
                  required
                  className={`${styles["filter-select-wght"]} focus:ring-0 p-2`}
                >
                  <option value="ALL">ALL</option>

                  {this.renderBatchOptions()}
                </select>
              </span>
              {claims["user_type"] !== "HOD" && (
                <span className="w-3/6 mt-1 flex flex-col">
                  <label className={styles["label"]}>Branch</label>
                  <select
                    required
                    value={poBranch}
                    onChange={this.branchPoChanged}
                    className={`${styles["filter-select-wght"]} focus:ring-0 p-2`}
                  >
                    <option value="ALL">ALL</option>

                    {this.renderBranchOptions()}
                  </select>
                </span>
              )}

              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}> Direct PO Weightage</label>
                <input
                  value={directPoW}
                  onChange={this.directPoWChange}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>
                  {" "}
                  Indirect PO Weightage
                </label>
                <input
                  value={indirectPoW}
                  onChange={this.indirectPoWChange}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>

              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>PO Target Attainment</label>
                <input
                  value={poTarget}
                  onChange={this.poTargetChanged}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>

              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Level 1 Max Range</label>
                <input
                  value={lvl1Range}
                  onChange={this.lvl1change}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>
              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Level 2 Max Range</label>
                <input
                  value={lvl2Range}
                  onChange={this.lvl2change}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>

              <span className="w-3/6 mt-1 flex flex-col">
                <label className={styles["label"]}>Level 3 Max Range</label>
                <input
                  value={lvl3Range}
                  onChange={this.lvl3change}
                  required
                  type="text"
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                  placeholder="EX: 50"
                />
              </span>
            </div>
            <div className="flex flex-row justify-end">
              <button
                disabled={poLoading}
                className={`${
                  poLoading && "cursor-not-allowed"
                } text-white bg-[#456BF1] rounded-lg text-md font-medium px-8 py-2 mr-5 mb-8 mt-4`}
                type="submit"
              >
                {poLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Update Changes"
                )}
              </button>
            </div>
          </form>
        )}
      </>
    );
  };

  okBtn = () => {
    this.setState({
      coCreated: false,
      poCreated: false,
      batch: "ALL",
      branch: "ALL",
      regulation: "ALL",
      semester: "ALL",
      coLoading: false,
      poLoading: false,
      intWeight: "",
      extWeight: "",
      directCoW: "",
      indirectCoW: "",
      poBranch: "ALL",
      poBatch: "ALL",
      poRegulation: "ALL",
      directPoW: "",
      indirectPoW: "",
      lvl1Range: "",
      lvl2Range: "",
      lvl3Range: "",
      poTarget: "",
    });
  };

  render() {
    const { match } = this.props;
    const { path } = match;
    const { activeTab, claims } = this.state;

    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div
          className={`${styles["home"]} flex flex-row overflow-hidden bg-[#FFFFFF]`}
        >
          <CampusNavBar />
          <div className="flex justify-between w-[100%] h-screen">
            <span className="w-[12%]">
              <SideBar />
            </span>
            <div className="bg-[#fff] w-[88%] relative h-screen overflow-hidden overflow-y-auto">
              <NavLink path={path} claims={claims} />

              <div className={styles["account-container"]}>
                <div className={styles["info-container"]}>
                  <span className="flex flex-row items-center">
                    <button
                      onClick={this.coWeightages}
                      type="button"
                      className={`hover:text-[#3f4451] ${
                        activeTab === 1
                          ? styles["overlay-tab-active"]
                          : styles["overlay-tab"]
                      } mb-1 mr-16`}
                    >
                      CO Weightages
                    </button>
                    <button
                      onClick={this.poWeightages}
                      type="button"
                      className={`hover:text-[#3f4451] ${
                        activeTab === 2 ? "overlay-tab-active" : "overlay-tab"
                      } mb-1`}
                    >
                      PO Weightages & Levels
                    </button>
                  </span>

                  {activeTab === 1
                    ? this.renderEditWeightagesView()
                    : this.renderPOView()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

export default ModifyWeightages;
