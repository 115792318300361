import styles from "./index.module.css";
import { useState, useEffect } from "react";
import apiClient from "../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import { Oval } from "react-loader-spinner";

const descDummy = [];

const FeedBack = (props) => {
  const { reload, cls } = props;
  const [created, updateCreated] = useState(false);
  const [data, updateData] = useState({});
  const [desc, updateDesc] = useState(descDummy);
  const [fb, updateFb] = useState([]);
  const [isLoading, updateLoading] = useState(false);

  const addFeedBack = async () => {
    try {
      updateLoading(true);
      const feedList = [];
      for (let index = 0; index < desc.length; index++) {
        const inputEl = document.getElementById(`${index + 1}`);
        feedList.push({
          coName: `CO${index + 1}`,
          feedbackValue: inputEl["value"],
          coId: desc[index]["coId"],
        });
      }

      const reqBody = {
        courseId: data["courseId"],
        feedbackList: feedList,
      };

      // console.log(reqBody);

      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(reqBody),
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_FACULTY_FEEDBACK,
        options
      );

      // console.log(response);

      if (response["ok"] === true) {
        const res = await response.json();

        if (res["statusCode"] === "OK") {
          updateCreated(true);
          reload();

          const { item } = props;
          updateData(item[0]);
          getDesc(item[0]["courseId"]);
          item[0]["feedbackCreated"] !== null &&
            item[0]["feedbackCreated"].toLowerCase() === "y" &&
            getFbData(item[0]["courseId"]);
        } else {
          alert(res["description"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
    updateLoading(false);
  };

  const renderOutcomes = () => {
    const ans = [];
    try {
      for (let index = 1; index <= desc.length; index++) {
        ans.push(
          <tr
            key={index}
            className="bg-[#fff] border border-blue-500 text-[#606981] font-medium p-0"
          >
            <th
              className={`${styles["labels"]} bg-[#fff] border border-blue-500 p-0 px-2 py-4`}
            >
              {data["courseCode"]}.{index}
            </th>
            <th className="bg-[#fff] border border-blue-500 p-0 px-2 py-4">
              <input
                value={desc[index - 1]["coDesc"]}
                type="text"
                className={`${styles["filter-select"]} focus:ring-0`}
                placeholder={`CO${index} Statement`}
              />
            </th>
            <th
              className={`${styles["label-dropdowns"]} bg-[#fff] border border-blue-500 p-0 px-2 py-4`}
            >
              <input
                defaultValue={
                  fb.length !== 0
                    ? fb?.[index - 1]?.["feedbackValue"] ?? ""
                    : ""
                }
                id={`${index}`}
                type="text"
                className={`${styles["labels"]} focus:ring-0 w-1/2 rounded border border-slate-200`}
                placeholder="98"
              />
            </th>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const getDesc = async (id) => {
    const token = Cookies.get("jwt_token");

    const response = await apiClient.get(
      apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION + `?courseId=${id}`,
      { Authorization: token }
    );

    if (response["data"]["statusCode"] === "OK") {
      updateDesc(response["data"]["result"]);
    } else {
      updateDesc(descDummy);
    }
  };

  const getFbData = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FB_VALUES + `?courseId=${id}`,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        updateFb(response["data"]["result"]);
      } else {
        updateFb([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const okBtn = () => {
    updateCreated(false);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const { item } = props;
      updateData(item[0]);
      getDesc(item[0]["courseId"]);
      item[0]["feedbackCreated"] !== null &&
        item[0]["feedbackCreated"].toLowerCase() === "y" &&
        getFbData(item[0]["courseId"]);
    }
  }, []);

  return (
    <>
      <span className="flex items-center justify-between">
        <h1 className={styles["overlay-title"]}>Course Feedback</h1>
        <RxCross2
          onClick={cls}
          size={26}
          className="cursor-pointer text-[#456bf1]"
        />
      </span>
      <div className="bg-[#FFFFFF] p-5 mt-6 mb-0">
        <span className="flex flex-row justify-between">
          <h1 className={`${styles["overlay-heading"]} underline mb-5 ml-2`}>
            Input Feedback Details
          </h1>
        </span>
      </div>
      {created ? (
        <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
          <img src="/assets/tick.svg" alt="new course" width={34} height={34} />
          <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
            Feedback Submitted successfully!
          </h1>

          <button
            onClick={okBtn}
            className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
            type="button"
          >
            Ok
          </button>
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-start flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <span className="mt-4 mr-5 flex flex-col">
              <label className={styles.label}> Regulation</label>
              <input
                disabled
                type="text"
                value={data["regulationName"]}
                className={`${styles["filter-select"]} focus:ring-0 mr-10 cursor-not-allowed`}
              />
            </span>
            <span className="mt-4 mr-5 flex flex-col">
              <label className={styles.label}> Batch</label>

              <input
                type="text"
                value={data["batch"]}
                disabled
                className={`${styles["filter-select"]} focus:ring-0 mr-10 cursor-not-allowed`}
              />
            </span>
            <span className=" mt-4 mr-5 flex flex-col">
              <label className={styles.label}> Branch</label>

              <input
                disabled
                type="text"
                value={data["branch"]}
                className={`${styles["filter-select"]} focus:ring-0 cursor-not-allowed`}
              />
            </span>
            <span className=" mt-4 mr-5 flex flex-col">
              <label className={styles.label}> Semester</label>
              <input
                disabled
                type="text"
                value={data["semester"]}
                className={`${styles["filter-select"]} focus:ring-0 cursor-not-allowed`}
              />
            </span>

            <span className=" mt-4 mr-5 flex flex-col">
              <label className={styles.label}> Course Code</label>
              <input
                disabled
                type="text"
                value={data["courseCode"]}
                className={`${styles["filter-select"]} focus:ring-0 mr-10 cursor-not-allowed`}
              />
            </span>
            <span className="mt-4 mr-5 flex flex-col">
              <label className={styles.label}>Course Name</label>
              <input
                disabled
                type="text"
                value={data["courseName"]}
                className={`${styles["filter-select"]} focus:ring-0 cursor-not-allowed`}
              />
            </span>
          </div>

          <div className="flex flex-col justify-start flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0 mt-10">
            <h2 className="mt-2 text-[#606981] font-medium font-Lato-normal text-lg mb-2">
              Course Feedback Input
            </h2>
            <div className={styles.table}>
              <table className="text-center border border-blue-500 w-full">
                <tbody>
                  <tr
                    className={`${styles.matrix} border border-blue-500 p-0 w-full`}
                  >
                    <th className="bg-[#fff] border border-blue-500 p-0 py-4">
                      Course Outcomes
                    </th>
                    <th className="bg-[#fff] border border-blue-500 p-0 py-4">
                      CO Statement
                    </th>
                    <th className="bg-[#fff] border border-blue-500 p-0 py-4">
                      Feedback Percentage
                    </th>
                  </tr>

                  {renderOutcomes()}
                </tbody>
              </table>
            </div>

            {/* <span className="flex flex-col items-center w-full text-[#606981] bg-[#ffffff] font-medium p-0 pl-6 mb-8">
              <span className="flex items-center w-full">
                <p
                  className={`${styles["co-statement-text"]} mb-4 mt-4 text-[#8F949F] mr-5`}
                >
                  Question 1:
                </p>

                <p
                  className={`${styles["filter-select-desc"]} bg-[#fff] border border-0 border-b p-0 m-0 flex break-words w-8/12`}
                >
                  Are you able to apply laws of physic using string theory
                </p>

                <p
                  className={`text-[#456BF1] bg-[#F4F9FF] rounded-3xl px-6 py-2 ml-10`}
                >
                  CO1
                </p>
              </span>

              <span className="flex flex-col w-11/12 ml-32 mt-1">
                <p
                  className={`${styles["filter-select-rating"]} bg-[#fff] p-0 m-0 flex w-8/12`}
                >
                  Rate how much you've learned from 1-5
                </p>

                <div className="flex items-center justify-between w-7/12 mt-2">
                  <span className={styles["star"]}>1</span>
                  <span className={styles["star"]}>2</span>
                  <span className={styles["star"]}>3</span>
                  <span className={styles["star"]}>4</span>
                  <span className={styles["star"]}>5</span>
                </div>
              </span>
            </span>

            <span className="flex flex-col items-center w-full text-[#606981] bg-[#ffffff] font-medium p-0 pl-6 mb-3">
              <span className="flex items-center w-full">
                <p
                  className={`${styles["co-statement-text"]} mb-4 mt-4 text-[#8F949F] mr-5`}
                >
                  Question 2:
                </p>

                <p
                  className={`${styles["filter-select-desc"]} bg-[#fff] border border-0 border-b p-0 m-0 flex break-words w-8/12`}
                >
                  Are you able to apply laws of physic using string theory
                </p>

                <p
                  className={`text-[#456BF1] bg-[#F4F9FF] rounded-3xl px-6 py-2 ml-10`}
                >
                  CO1
                </p>
              </span>

              <span className="flex flex-col w-11/12 ml-32 mt-1">
                <p
                  className={`${styles["filter-select-rating"]} bg-[#fff] p-0 m-0 flex w-8/12`}
                >
                  Rate how much you've learned from 1-5
                </p>

                <div className="flex items-center justify-between w-7/12 mt-2">
                  <span className={styles["star"]}>1</span>
                  <span className={styles["star"]}>2</span>
                  <span className={styles["star"]}>3</span>
                  <span className={styles["star"]}>4</span>
                  <span className={styles["star"]}>5</span>
                </div>
              </span>
            </span> */}
          </div>
          <span className="flex flex-row justify-end items-end mt-10 mb-5">
            <button
              disabled={isLoading}
              onClick={addFeedBack}
              className={`${
                isLoading && "cursor-not-allowed"
              } font-Lato-normal self-end text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mr-5 mt-4`}
              type="button"
            >
              {isLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#FFF"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Submit"
              )}
            </button>
          </span>
        </>
      )}
    </>
  );
};

export default FeedBack;
