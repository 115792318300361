import { Component } from "react";
import InitialSetup from "../../InitialSetup";
import SideBar from "../../layouts/SideBar";
import ObeNavBar from "../../layouts/ObeNavBar";
import "./index.css";
import "../../LoginForm/index.css";
import ManageFaculty from "./ManageFaculty";
import Analysis from "./Analysis";
import FiltersContext from "../../../context/FiltersContext";
import FacultyCourseMapping from "./FacultyCourseMapping";

class FacultyPage extends Component {
  state = { setUp: false };
  static contextType = FiltersContext;

  componentDidMount() {
    const { filtersBatch } = this.context;
    this.setState({ setUp: filtersBatch.length > 0 });
  }

  renderView = () => {
    const { setUp } = this.state;
    try {
      switch (setUp) {
        case true:
          const { match } = this.props;
          const { path } = match;
          return path.includes("/obe/faculty/analysis") ? (
            <div className="flex flex-col min-h-full overflow-hidden pb-5">
              <Analysis />
            </div>
          ) : path.includes("/obe/faculty/faculty-course") ? (
            <div className="flex flex-col min-h-full overflow-y-hidden pb-5">
              <FacultyCourseMapping />
            </div>
          ) : (
            <div className="flex flex-col min-h-full overflow-y-hidden pb-5">
              <ManageFaculty />
            </div>
          );
        case false:
          return <InitialSetup />;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div className="home flex flex-row overflow-hidden bg-[#FFFFFF]">
          <ObeNavBar />
          <div className="flex justify-between w-[100%] h-screen">
            <span className="w-[12%]">
              <SideBar />
            </span>
            <div className="bg-[#fff] w-[88%] relative h-screen overflow-y-auto">
              {this.renderView()}
            </div>
          </div>
        </div>
      </span>
    );
  }
}

export default FacultyPage;
