import styles from "./index.module.css";
import { withRouter } from "react-router-dom";
import * as Highcharts from "highcharts";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
require("highcharts/modules/exporting");

const levels = {
  L1: "Remember",
  L2: "Understand",
  L3: "Apply",
  L4: "Analyse",
  L5: "Evaluate",
  L6: "Create",
};

const QuestionPaperAnalysis = (props) => {
  const [analysisData, updateAnalysisData] = useState({});
  const [loading, updateLoading] = useState(true);
  const [activeTemp, updateActiveTemp] = useState("");
  const [abortController, setAbortController] = useState(null);

  const {
    data,
    templateName,
    mappedGoBack,
    isAttainment,
    templateList,
    courseCode,
  } = props;

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const fetchAnalysis = async () => {
    updateLoading(true);
    updateAnalysisData({});

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    setAbortController(controller);

    const { signal } = controller;

    const options = {
      method: "GET",
      headers,
    };

    try {
      const res = await fetch(
        apiClient.urls.fatcat.OBE_QUESTION_PAPER_ANALYSIS +
          `?courseId=${
            isAttainment ? courseCode : data?.courseId
          }&templateName=${isAttainment ? activeTemp : templateName}`,
        { ...options, signal }
      );

      if (res.ok) {
        const data = await res.json();
        updateAnalysisData(data);
      }
      updateLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const renderEmptyView = (text) => (
    <>
      {isAttainment && (
        <ul className={styles["template-ul"]}>{renderTemplateList()}</ul>
      )}
      <div className="flex justify-center items-center p-10 mt-16 mb-5">
        <p className={styles["empty-view"]}>{text}</p>
      </div>
    </>
  );

  const renderScreen = () => {
    const typeName = isAttainment
      ? activeTemp === ""
        ? "Overall Assessment Analysis"
        : activeTemp
      : templateName === ""
      ? "Overall Assessment Analysis"
      : templateName;
    return (
      <div
        className={`${
          isAttainment ? styles["bg-containers"] : styles["analysis-container"]
        } p-5`}
      >
        {isAttainment && (
          <ul className={styles["template-ul"]}>{renderTemplateList()}</ul>
        )}
        <p className={styles["assmnt-type-heading"]}>Assessment Type</p>
        <p className={styles["assmnt-title"]}>{typeName}</p>

        <div className="mt-5 flex items-center justify-between">
          <div className={styles["bar-chart"]}>
            <p>COURSE OUTCOME WISE MARK DISTRIBUTION</p>

            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>

          <div className={`${styles["bar-chart"]} bg-[#F6F5FF]`}>
            <p>BLOOM'S LEVEL WISE MARK DISTRIBUTION</p>

            <HighchartsReact highcharts={Highcharts} options={optionsPie} />
          </div>
        </div>

        <div className="text-center flex flex-col mt-14">
          <p className={styles["table-title"]}>Question Paper Analysis</p>
          <table className={`${styles["qp-analysis-table"]} mt-5`}>
            <tr>
              <th>Outcome</th>
              <th>
                Total <br /> Questions
              </th>
              <th>
                Percentage <br /> Distribution
              </th>
              {/* <th>Assessed PIs</th>
              <th>
                Non Assessed
                <br /> PIs
              </th> */}
              {templateName !== "" && (
                <th>
                  Question
                  <br /> Numbers
                </th>
              )}
              <th>Bloom's Level</th>
            </tr>

            {Object.keys(analysisData?.coAnalysis).map((each) => (
              <tr>
                <td className="text-[#263238] font-medium">{each}</td>
                <td className="text-[#263238] font-medium">
                  {analysisData?.coAnalysis?.[each]?.totalQuestionsCount}
                </td>
                <td className="text-[#263238] font-black">
                  {analysisData?.coAnalysis?.[each]?.pctDist}%
                </td>
                {/* <td className="font-semibold text-[#606981]">1.4.1, 1.4.2</td>
              <td className="font-semibold text-[#606981]">
                1.3.1, 1.3.2, 1.3.3, 1.4.4
              </td> */}
                {templateName !== "" && (
                  <td className="font-semibold text-[#606981]">
                    {analysisData?.coAnalysis?.[each]?.questionNo.join(", ")}
                  </td>
                )}
                <td className="text-[#263238] font-medium">
                  {analysisData?.coAnalysis?.[each]?.bloomsLvl.join(", ")}
                </td>
              </tr>
            ))}

            <tr>
              <td>Total</td>
              <td>{analysisData?.totalQuestions}</td>
              <td className="font-black"></td>
              <td className="font-semibold text-[#606981]"></td>
              {templateName !== "" && <td></td>}
            </tr>
          </table>
        </div>

        <div className="text-center flex flex-col mt-12">
          <p className={styles["table-title"]}>
            Taxanomy Question Paper Analysis
          </p>
          <table className={`${styles["qp-analysis-table"]} mt-5`}>
            <tr>
              <th>Bloom's Level</th>

              <th>
                Total <br /> Questions
              </th>
              <th>Percentage</th>
              {templateName !== "" && <th>Question Numbers</th>}
              {/* <th>Action Verbs</th> */}
            </tr>

            {Object.keys(analysisData?.taxonomyAnalysis).map((each) => (
              <tr>
                <td className="text-[#263238] font-medium">{levels[each]}</td>
                <td className="text-[#263238] font-medium">
                  {analysisData?.taxonomyAnalysis?.[each]?.totalQuestionsCount}
                </td>
                <td className="text-[#263238] font-black">
                  {analysisData?.taxonomyAnalysis?.[each]?.pctDist}%
                </td>
                {templateName !== "" && (
                  <td className="font-semibold text-[#606981]">
                    {analysisData?.taxonomyAnalysis?.[each]?.questionNo.join(
                      ", "
                    )}
                  </td>
                )}
                {/* <td>define, identify, label</td> */}
              </tr>
            ))}
          </table>
        </div>
      </div>
    );
  };

  const goBack = () => {
    mappedGoBack();
  };

  const renderContent = () =>
    Object.keys(analysisData).length === 0
      ? renderEmptyView("No Analysis Data Found")
      : renderScreen();

  useEffect(() => {
    isAttainment && updateActiveTemp(templateList[0] ?? "");
  }, [templateList]);

  useEffect(() => {
    fetchAnalysis();
  }, [activeTemp]);

  const options = {
    chart: {
      type: "column",
      width: 500,
      height: 300,
    },

    title: {
      text: "",
      verticalAlign: "middle",
      align: "center",
      style: {
        color: "#9FB2F6",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "900",
      },
    },

    xAxis: {
      categories:
        Object.keys(analysisData).length > 0
          ? Object.keys(analysisData?.coAnalysis)
          : [],
      lineColor: "#EBEBEB",
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        style: {
          color: "#263238",
          fontFamily: "Merriweather Sans",
          fontWeight: "400",
          fontSize: "14px",
        },
      },
    },

    yAxis: {
      min: 0,
      lineColor: "#EBEBEB",
      lineWidth: 1,
      title: {
        text: null,
      },

      labels: {
        style: {
          color: "#263238",
          fontFamily: "Merriweather Sans",
          fontWeight: "400",
          fontSize: "14px",
        },
      },
    },

    accessibility: {
      enabled: false,
    },

    plotOptions: {
      column: {
        borderWidth: 0,
        pointWidth: 30,
        maxPointWidth: 30,
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "Merriweather Sans",
            fontWeight: "500",
            fontSize: "14px",
          },
        },
      },
    },

    legend: {
      y: 20,
      align: "left",
      verticalAlign: "bottom",
      layout: "horizontal",
      symbolRadius: 0,
      itemStyle: {
        color: "#8F949F",
        fontWeight: "normal",
      },
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: "CO Marks Distribution",
        color: "#456BF1",
        data:
          Object.keys(analysisData).length > 0
            ? Object.keys(analysisData?.coAnalysis).map((each) => ({
                name: `${each}`,
                y: analysisData?.coAnalysis?.[each]?.marks,
              }))
            : [],
      },
    ],
  };

  const innerWidth = window.innerWidth;

  const optionsPie = {
    chart: {
      type: "pie",
      width: 500,
      height: 300,
      backgroundColor: "transparent",
    },
    title: {
      margin: 0,
      text: "",
    },

    xAxis: {
      lineWidth: 0,
    },

    yAxis: {
      lineWidth: 0,
    },

    colors: ["#8D77F3", "#F6416C", "#FCA551", "#456BF1", "#27AE7A", "#428BCA"],

    legend: {
      floating: true,
      x: innerWidth < 1280 ? -120 : -30,
      symbolRadius: 0,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginTop: 14,
      itemStyle: {
        fontSize: "13px",
        fontFamily: "Merriweather Sans",
        fontWeight: "400",
        color: "#6F7176",
      },
    },

    accessibility: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        center: innerWidth < 1280 ? [100, 120] : [160, 120],
        allowPointSelect: true,
        size: innerWidth < 1280 ? 140 : 180,
        cursor: "pointer",
        borderWidth: 0,
        borderRadius: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}",
          distance: -18,
          style: {
            color: "#FFFFFF",
            fontFamily: "Merriweather Sans",
            fontSize: "14px",
            fontWeight: "500",
            textOutline: "none",
          },
        },
        showInLegend: true,
      },
    },

    series: [
      {
        type: "pie",
        name: "Blooms level mark distribution",
        innerSize: "60%",
        data:
          Object.keys(analysisData).length > 0
            ? Object.keys(analysisData?.taxonomyAnalysis).map((each) => ({
                name: `${levels[each]} (${each})`,
                y: analysisData?.taxonomyAnalysis?.[each]?.marks,
              }))
            : [],
      },
    ],
  };

  const templateChanged = (event) => {
    const val = event.target.id;
    updateActiveTemp(activeTemp === val ? "" : val);
  };

  const renderTemplateList = () => {
    const ans = [];
    try {
      for (const item of templateList) {
        ans.push(
          <li
            className={
              activeTemp === item
                ? styles["template-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item}
            id={item}
            onClick={templateChanged}
          >
            {item}
          </li>
        );
      }
    } catch (err) {
      console.log(err);
    }
    return ans;
  };

  return loading ? (
    <div className={`fixed top-2/4 left-2/4 ${isAttainment && "top-3/4"}`}>
      <Oval
        height={70}
        width={70}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  ) : (
    <>
      {renderContent()}
      {!isAttainment && (
        <span className={`flex flex-row justify-end items-end mb-2 mt-12`}>
          <button onClick={goBack} className={styles["back-button"]}>
            Go Back
          </button>
        </span>
      )}
    </>
  );
};

export default withRouter(QuestionPaperAnalysis);
