import styles from "./index.module.css";
import { RiPencilFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { useState, useEffect, useContext } from "react";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import FiltersContext from "../../../../../context/FiltersContext";
import { RxCross2 } from "react-icons/rx";
import { Oval } from "react-loader-spinner";
import Popup from "reactjs-popup";

const FacultyCourseList = (props) => {
  const { item, index, getCourses } = props;

  const { filtersBranch } = useContext(FiltersContext);
  const [loader, updateLoader] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [facultyData, updateFacultyData] = useState([]);
  const [selectedFacId, updateFacId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFaculties, setFilteredFaculties] = useState(facultyData);
  const [facBranch, updateFacBranch] = useState("");
  const [popUpDelete, updatePopUpDelete] = useState(false);
  const [editFaculty, updateEditFaculty] = useState(false);

  // Handle input change and update search query
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const facBranchChanged = (e) => {
    updateFacId("");
    updateFacBranch(e.target.value);
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of filtersBranch) {
        ans.push(
          <option value={item.optionValue}>{item["optionDesc"]}</option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const selectFaculty = (event) => {
    updateFacId(event.target.id);
    toggleDropdown();
  };

  const getFaculty = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_DETAILS +
          `?branch=${facBranch}&facultyType=faculty&semester=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateFacultyData(response["data"]["result"]);
      } else {
        updateFacultyData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const facMapping = async () => {
    updateLoader(true);
    try {
      if (selectedFacId === "") {
        alert("Please select faculty.");
        updateLoader(false);
        return;
      }

      const reqBody = {
        facultyCourseSectionList: item?.sectionName,
        courseDetailsId: item?.courseId,
        facultyId: selectedFacId,
      };

      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(reqBody),
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_FACULTY_COURSE_MAPPING,
        options
      );

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updateLoader(false);
          updateFacId("");
          updateEditFaculty(false);
          getCourses();
        } else {
          alert(res["description"]);
        }
      } else {
        alert("Faculty-Course Mapping is Unsuccessful!");
      }
    } catch (err) {
      console.log(err);
      alert("Faculty-Course Mapping is Unsuccessful!");
    }
    updateLoader(false);
  };

  const closeBtn = () => {
    setOpenDropdown(false);
  };

  const renderFacName = () => {
    let name = "";
    try {
      const res = facultyData.filter(
        (each) => `${selectedFacId}` === `${each?.facultyId}`
      );

      name = res?.[0]?.["facultyName"] ?? "";
    } catch (err) {
      console.log(err);
    }

    return name;
  };

  const deleteMapping = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.DELETE_COURSE_MAPPING +
          `?courseId=${item?.courseId}&facultyId=${item?.facultyId}`,
        options
      );

      if (response.ok === true) {
        const res = await response.json();

        if (res["statusCode"] === "OK") {
          await getCourses();
          await getCourses();
          alert("Course Mapping deleted successfully");
        } else {
          alert("Oops! Course Mapping not deleted");
        }
      } else {
        alert("Oops! Course Mapping not deleted");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openModalDelete = () => {
    updatePopUpDelete(true);
  };

  const closeModalDelete = () => {
    updatePopUpDelete(false);
  };

  const deleteCourseItem = () => {
    updatePopUpDelete(false);
    deleteMapping();
  };

  const renderDeletePopup = () => (
    <div className={`${styles["modal"]} rounded-lg flex flex-col`}>
      <p className={`${styles["course-title"]} text-center mb-10`}>
        Do you want to delete the Course Mapping?
      </p>
      <span>
        <button
          onClick={closeModalDelete}
          className="mr-9 cursor-pointer text-blue-600 font-medium bg-[#F4F9FF] rounded px-6 py-2 border border-blue-600"
        >
          No
        </button>
        <button
          onClick={deleteCourseItem}
          className="cursor-pointer text-blue-600 font-medium bg-red-600 text-white rounded px-6 py-2 border"
        >
          Yes
        </button>
      </span>
    </div>
  );

  const editFac = () => {
    updateEditFaculty(!editFaculty);
  };

  const overlayStyle = { background: "rgba(0,0,0,0.5)", position: "absolute" };

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredFaculties(facultyData); // Reset to full list if no search query
    } else {
      const filtered = facultyData.filter((faculty) =>
        faculty.facultyName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredFaculties(filtered);
    }
  }, [searchQuery, facultyData]);

  useEffect(() => {
    openDropdown && getFaculty();
  }, [facBranch, openDropdown]);

  return (
    <li
      className={`${styles.labels} relative flex flex-col justify-center m-0 ${
        index % 2 === 0 ? "bg-[#F5F8FC]" : "bg-[#FFF]"
      }`}
    >
      <div
        className={`flex flex-row items-center text-[#606981] p-2 pr-0 pl-6`}
      >
        <p className="text-nowrap truncate w-2/12 text-base flex flex-row justify-start items-center">
          {item?.courseCode}
        </p>

        <p className="text-nowrap truncate w-2/12 ml-1 text-xs md:text-base flex flex-row justify-start items-center">
          {item?.courseShortName}
        </p>

        <p className="text-nowrap truncate w-1/12 ml-3 text-xs md:text-base flex flex-row justify-start">
          {item?.sectionName}
        </p>

        {item?.facultyId === null || editFaculty ? (
          <>
            {selectedFacId !== "" ? (
              <p
                onClick={toggleDropdown}
                className="cursor-pointer text-nowrap truncate w-1/12 ml-0 mr-20  text-base flex flex-row items-center"
              >
                {renderFacName()}
              </p>
            ) : (
              <button
                type="button"
                className={`${styles["choose-btn"]} text-blue-600 ml-0 mr-10 bg-transparent border border-[#E2E9FF] rounded p-2`}
                onClick={toggleDropdown}
              >
                Choose Faculty
              </button>
            )}
            {openDropdown && (
              <div
                className={` ${styles["dropdown"]} z-10 bg-white border border-gray-300 p-2 mt-1`}
              >
                <span className="flex justify-between items-center mb-4">
                  <select
                    className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none p-2`}
                    onChange={facBranchChanged}
                    value={facBranch}
                  >
                    <option value="">Faculty Branch</option>
                    {renderBranchOptions()}
                  </select>
                  <RxCross2
                    onClick={closeBtn}
                    size={20}
                    className="cursor-pointer text-[#456bf1]"
                  />
                </span>

                <div className={styles["search-input"]}>
                  <img src="/assets/search.svg" alt="search" />
                  <input
                    type="search"
                    placeholder="Search for Faculty"
                    className={`${styles["input"]} p-1 border border-gray-300 w-full`}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>

                <ul className="dropdown-list">
                  {filteredFaculties.length > 0 ? (
                    filteredFaculties.map((faculty, index) => (
                      <li
                        key={index}
                        id={faculty.facultyId}
                        onClick={selectFaculty}
                        className="p-1 hover:bg-gray-100"
                      >
                        {faculty.facultyName}
                      </li>
                    ))
                  ) : (
                    <li className="p-1 text-gray-400">No results found</li>
                  )}
                </ul>
              </div>
            )}
          </>
        ) : (
          <p className={`truncate w-2/12  flex flex-row items-center`}>
            {item.facultyName}
          </p>
        )}

        {!item?.facultyId || editFaculty ? (
          loader ? (
            <div
              className={`flex flex-row justify-center items-center w-1/12 ml-16`}
            >
              <Oval
                height={20}
                width={20}
                color="#3d65f4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3d65f4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <button
              onClick={facMapping}
              type="button"
              className={`${styles["map-button"]} text-blue-600 ml-12 xl:ml-16 2xl:ml-28 flex flex-row justify-center items-center bg-transparent border border-[#E2E9FF] rounded p-2 text-center`}
            >
              Map
            </button>
          )
        ) : (
          <p className="text-nowrap truncate w-2/12 ml-16 xl:ml-10 text-xs md:text-base flex flex-row justify-start items-center">
            <img
              className="rounded-xl"
              src="/assets/greenTick.svg"
              alt="tick"
              width={24}
              height={24}
            />
            <span className="flex ml-2">Mapped</span>
          </p>
        )}

        {item?.facultyId && (
          <span className="hidden md:flex flex-row items-center ml-auto mr-10">
            <div
              onClick={editFac}
              className={`${styles["popup-btn"]}`}
              style={{ backgroundColor: "#EFF3F9" }}
            >
              <RiPencilFill
                className={` text-blue-600 cursor-pointer`}
                size={20}
              />
            </div>

            <div
              onClick={openModalDelete}
              className={`${styles["popup-btn"]} ml-5`}
              style={{ backgroundColor: "#EFF3F9" }}
            >
              <MdDeleteOutline
                className="text-red-600 cursor-pointer"
                size={20}
              />
              <Popup
                open={popUpDelete}
                onClose={closeModalDelete}
                {...{ overlayStyle }}
              >
                {renderDeletePopup()}
              </Popup>
            </div>
          </span>
        )}
      </div>
    </li>
  );
};

export default FacultyCourseList;
