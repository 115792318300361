import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Highcharts from "highcharts";
import { Oval } from "react-loader-spinner";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import { useEffect, useState } from "react";
highchartsMore(Highcharts);
require("highcharts/modules/exporting");

const Analysis = (props) => {
  const [itemAnalysis, updateItemAnalysis] = useState({
    q1: [],
    q2: [],
    q3: [],
    q4: [],
    outliers: [],
  });

  const [bloomsData, updateBloomsData] = useState([]);
  const [loader, updateLoader] = useState(false);

  const { data, courseCode } = props;

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  const getGraphOptions = () => {
    const { q1, q2, q3, q4, outliers } = itemAnalysis;

    const option = {
      chart: {
        type: "scatter",
        height: 550,
        width: 810,
      },
      title: {
        margin: 30,
        text: "Item Analysis",
        align: "center",
        style: {
          color: "#606981",
          fontSize: "14px",
          fontWeight: "600",
        },
      },

      xAxis: [
        {
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1.1,

          tickInterval: 0.1,
          title: {
            text: null,
          },
          labels: {
            overflow: "justify",
            y: 20,
            x: 30,
          },

          gridLineColor: "#456BF1",
          gridLineWidth: 0.5,
          tickLength: 30,
          tickColor: "#456BF1",
          tickWidth: 0.5,
        },
        {
          offset: 30,

          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [0, 0.364, 0.636, 1],
          gridLineWidth: 0,
          tickWidth: 0.5,
          tickLength: 30,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
            y: 20,
            x: 100,

            formatter: function () {
              if (this.value === 0.636) {
                return `<span style="color:#1C60FF">Easy</span>`;
              } else if (this.value === 0.364) {
                return `<span style="color:#27AE7A">Medium</span>`;
              } else if (this.value === 0) {
                return `<span style="color:#E35050">Hard</span>`;
              }
            },
          },
        },
        {
          offset: 60,
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [],

          gridLineWidth: 0,
          tickWidth: 0,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
          },
        },
      ],

      yAxis: [
        {
          min: 0,
          max: 1,
          tickInterval: 0.1,
          title: {
            text: null,
          },
          labels: {
            overflow: "justify",
            y: 20,
          },

          gridLineColor: "#456BF1",
          gridLineWidth: 0.5,

          tickWidth: 0.5,
          tickLength: 40,
          tickColor: "#456BF1",
        },

        {
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },

          tickWidth: 0.5,
          tickLength: 60,
          tickColor: "#456BF1",
          tickPositions: [0, 0.2, 0.3, 0.4, 1],
          gridLineWidth: 0,

          labels: {
            overflow: "justify",
            y: 18,
            x: -5,

            formatter: function () {
              if (Math.ceil(Number(this.value) * 10) === 10) {
                return `<span style="color:#1C60FF">Excellent</span>`;
              } else if (Math.ceil(Number(this.value) * 10) === 4) {
                return `<span style="color:#27AE7A">Good</span>`;
              } else if (Math.ceil(Number(this.value) * 10) === 3) {
                return `<span style="color:#27AE7A">Fair</span>`;
              } else if (Math.ceil(Number(this.value) * 10) === 2) {
                return `<span style="color:#E35050">Poor</span>`;
              }
            },
          },
        },
        {
          offset: 100,
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [],

          gridLineWidth: 0,
          tickWidth: 0,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
          },
        },
      ],

      accessibility: {
        enabled: false,
      },

      plotOptions: {
        scatter: {
          color: "#E35050",
          dataLabels: {
            allowOverlap: true,
            enabled: true,
            verticalAlign: "middle",
            align: "center",
            borderRadius: 20,
            style: {
              textOutline: "none",
              color: "#FFFFFF",
              fontSize: 12,
              fontWeight: 400,
              borderRadius: 10,
            },
          },
        },
      },

      legend: {
        enabled: false,
        y: 20,
        align: "left",
        verticalAlign: "bottom",
        layout: "horizontal",
        symbolRadius: 0,
        itemStyle: {
          color: "#8F949F",
          fontWeight: "normal",
        },
      },

      credits: {
        enabled: false,
      },

      series: [
        // below code is for the color quadrants

        {
          type: "area",
          lineWidth: 0,
          color: "#E0EAFF",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [0.7, 0.4],
            [0.4, 0.4],
            [0.4, 0.2],
            [0.7, 0.4],
            [0.7, 0.2],
            [0.4, 0.2],
            [0.4, 0.4],
            [0.4, 0.2],
            [0.7, 0.4],
            [0.7, 0.2],
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#FFE6E6",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [0.7, 0.6], //top-right
            [0.4, 0.6], //top-left
            [0.4, 0.4], //bottom-left
            [0.7, 0.6], //top-right
            [0.7, 0.4], //bottom-right
            [0.4, 0.4], //bottom-left
            [0.4, 0.6], //top-left
            [0.4, 0.4], //bottom-left
            [0.7, 0.6], //top-right
            [0.7, 0.4], // bottom-right
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#EFEDFF",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [1, 0.7], //top-right
            [0.7, 0.7], //top-left
            [0.7, 0.4], //bottom-left
            [1, 0.7], //top-right
            [1, 0.4], //bottom-right
            [0.7, 0.4], //bottom-left
            [0.7, 0.7], //top-left
            [0.7, 0.4], //bottom-left
            [1, 0.7], //top-right
            [1, 0.4], // bottom-right
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#E0FFF3",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [1, 0.4], //top-right
            [0.7, 0.4], //top-left
            [0.7, 0.2], //bottom-left
            [1, 0.4], //top-right
            [1, 0.2], //bottom-right
            [0.7, 0.2], //bottom-left
            [0.7, 0.4], //top-left
            [0.7, 0.2], //bottom-left
            [1, 0.4], //top-right
            [1, 0.2], // bottom-right
          ],
        },

        // //////////////////////////////////////

        {
          data: outliers,

          type: "scatter",
          name: "Others",
          color: "#E35050",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q2,

          type: "scatter",
          name: "Hard",
          color: "#F86E7F",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q3,

          type: "scatter",
          name: "Easy",
          color: "#456BF1",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q1,

          type: "scatter",
          name: "2015",
          color: "#855CF8",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q4,

          type: "scatter",
          name: "Medium",
          color: "#27AE7A ",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },
      ],
    };

    return option;
  };

  const renderItemAnalysisGraph = () => {
    const options = getGraphOptions();

    return (
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    );
  };

  const bloomsCourseName = () => {
    let ans = [];

    try {
      ans.push(
        <tr
          className={`${styles["table-th"]} border border-blue-500 text-[#040C58]`}
        >
          <th
            className="border border-blue-500 p-2"
            rowSpan={bloomsData.length + 1}
          >
            {data?.courseShortName ?? ""}
          </th>
        </tr>
      );
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBloomsTable = () => {
    try {
      let ans = [];

      for (let each of bloomsData) {
        if (each["bloomsLvl"] === "Count") {
          ans.push(
            <tr className="border border-blue-500 text-[#040C58] font-normal">
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["bloomsLvl"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q1Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q2Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q3Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q4Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["qNoCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["totalQCount"]}
              </td>
            </tr>
          );
        } else if (each["bloomsLvl"] === "Percent of Questions") {
          ans.push(
            <tr className="border border-blue-500 text-[#040C58] font-bold">
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                % of Questions
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ1"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ2"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ3"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ4"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQNo"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {""}
              </td>
            </tr>
          );
        } else {
          ans.push(
            <tr className="border border-blue-500 text-[#040C58] font-normal">
              <td
                className={`${styles["table-td"]} capitalize border border-blue-500 p-2 text-[#606981]`}
              >
                {each["bloomsLvl"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q1LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q2LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q3LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q4LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["qNoLvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["totalLvlCount"]}
              </td>
            </tr>
          );
        }
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const filteringItemAnalysis = (res) => {
    const q1 = [];
    const q2 = [];
    const q3 = [];
    const q4 = [];
    const outliers = [];

    for (let each of res) {
      if (
        each["FV"] >= 0.4 &&
        each["FV"] < 0.7 &&
        each["DI"] >= 0.2 &&
        each["DI"] < 0.4
      ) {
        q3.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.7 &&
        each["FV"] < 1 &&
        each["DI"] >= 0.2 &&
        each["DI"] < 0.4
      ) {
        q4.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.4 &&
        each["FV"] < 0.7 &&
        each["DI"] >= 0.4 &&
        each["DI"] < 0.6
      ) {
        q2.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.7 &&
        each["FV"] < 1 &&
        each["DI"] >= 0.4 &&
        each["DI"] < 0.7
      ) {
        q1.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else {
        outliers.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      }
    }
    updateItemAnalysis({ q1, q2, q3, q4, outliers });
  };

  const getItemAnalysis = async () => {
    try {
      const token = Cookies.get("jwt_token");
      updateItemAnalysis({
        q1: [],
        q2: [],
        q3: [],
        q4: [],
        outliers: [],
      });

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_COURSES_ITEM_ANALYSIS +
            `?courseId=${courseCode}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          filteringItemAnalysis(response["data"]["result"]);
        } else {
          updateItemAnalysis({ q1: [], q2: [], q3: [], q4: [], outliers: [] });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBloomsLvl = async () => {
    try {
      updateLoader(true);
      updateBloomsData([]);
      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_CATEGORIZATION_QSTNS_BLOOM_LVL +
            `?courseId=${courseCode}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updateBloomsData(response["data"]["result"]);
        } else {
          updateBloomsData([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
    updateLoader(false);
  };

  useEffect(() => {
    getItemAnalysis();
    getBloomsLvl();
  }, [courseCode]);

  return (
    <div className={styles["bg-containers"]}>
      {loader ? (
        renderLoader()
      ) : (
        <>
          <div className="flex flex-row justify-center w-full pb-5">
            {renderItemAnalysisGraph()}
          </div>

          <p className="font-semibold text-sm text-[#606981] text-center mt-10">
            Categorization Of Questions Vs Blooms Level
          </p>

          <div className="flex flex-col mt-20 lg:mt-4 w-full overflow-x-auto mb-8">
            <table className="text-center border-0 border-blue-500">
              <tbody>
                <tr
                  className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-2">Course Name</th>
                  <th className="border border-blue-500 p-2">
                    Blooms&apos;s Level
                  </th>
                  <th className="border border-blue-500 p-2">Q1</th>
                  <th className="border border-blue-500 p-2">Q2</th>
                  <th className="border border-blue-500 p-2">Q3</th>
                  <th className="border border-blue-500 p-2">Q4</th>
                  <th className="border border-blue-500 p-2">
                    Not In quadrants
                  </th>
                  <th className="border border-blue-500 p-2">Total</th>
                </tr>
                {bloomsCourseName()}
                {renderBloomsTable()}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Analysis;
