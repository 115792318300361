import MyCourses from "./MyCourses";
import SideBar from "../../layouts/SideBar";
import FacultyNavBar from "../../layouts/FacultyNavBar";
import "./index.css";
import "../../LoginForm/index.css";

const FacultyLearnPage = () => {
  return (
    <span className="flex flex-row justify-center h-screen overflow-hidden">
      <div className="home flex flex-row overflow-hidden bg-[#FFFFFF]">
        <FacultyNavBar />
        <div className="flex justify-between w-[100%] h-screen">
          <span className="w-[12%]">
            <SideBar />
          </span>
          <div className="bg-[#fff] w-[88%] relative h-screen overflow-y-auto">
            <div className="flex flex-col overflow-hidden">
              <MyCourses />
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default FacultyLearnPage;
