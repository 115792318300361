import { Component } from "react";
import { Link } from "react-router-dom";

class NavLink extends Component {
  render() {
    const { path, claims } = this.props;

    return (
      <div className="flex flex-row mt-24 ml-8">
        <Link to="/obe/account">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/account"
                ? "font-bold underline text-[#040C58]"
                : "font-medium text-[#464D90]"
            } ml-4 underline-offset-8 decoration-2 cursor-pointer text-sm xl:text-base`}
          >
            MY ACCOUNT
          </h1>
        </Link>

        <Link to="/obe/campus-setup">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/campus-setup"
                ? "font-bold underline text-[#040C58]"
                : "font-medium text-[#464D90]"
            } ml-8 underline-offset-8 decoration-2 cursor-pointer text-sm xl:text-base`}
          >
            MANAGE CAMPUS SETUP
          </h1>
        </Link>
        {claims["user_type"] === "PRINCIPAL" && (
          <Link to="/obe/hods">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/hods"
                  ? "font-bold underline text-[#040C58]"
                  : "font-medium text-[#464D90]"
              } ml-8 underline-offset-8 decoration-2 cursor-pointer text-sm xl:text-base`}
            >
              MANAGE HODs
            </h1>
          </Link>
        )}
        {(claims["user_type"] === "PRINCIPAL" ||
          claims["user_type"] === "HOD") && (
          <Link to="/obe/modify-weightages">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/modify-weightages"
                  ? "font-bold underline text-[#040C58]"
                  : "font-medium text-[#464D90]"
              } ml-8 underline-offset-8 decoration-2 cursor-pointer text-sm xl:text-base`}
            >
              MODIFY WEIGHTAGES
            </h1>
          </Link>
        )}
        {claims["user_type"] === "PRINCIPAL" && (
          <Link to="/obe/bulk-migration">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/bulk-migration"
                  ? "font-bold underline text-[#040C58]"
                  : "font-medium text-[#464D90]"
              } ml-8 underline-offset-8 decoration-2 cursor-pointer text-sm xl:text-base`}
            >
              BULK MIGRATION
            </h1>
          </Link>
        )}
      </div>
    );
  }
}

export default NavLink;
