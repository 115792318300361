import { Component } from "react";
import apiClient from "../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import styles from "./index.module.css";
import "../LoginForm/index.css";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting");

class Pdf extends Component {
  state = {
    psoData: [],
    internalData: [],
    externalData: [],
    res: {},
    loading: "loading",
    fb: [],
    desc: [],
    art: [],
    pdfData: [],
    psos: 0,
    userType: "",
    sec: [],
    observation: "",
    itemAnalysis: {
      q1: [],
      q2: [],
      q3: [],
      q4: [],
      outliers: [],
    },
    customTxt: "",
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.apiCalls(id);
    const token = Cookies.get("jwt_token");
    const claim = jose.decodeJwt(token);
    this.setState({ userType: claim });
  }

  getGraphOptions = () => {
    const { itemAnalysis } = this.state;
    const { q1, q2, q3, q4, outliers } = itemAnalysis;

    const option = {
      chart: {
        type: "scatter",
        height: 370,
        width: 700,
      },
      title: {
        margin: 30,
        text: "Item Analysis",
        align: "center",
        style: {
          color: "#606981",
          fontSize: "14px",
          fontWeight: "600",
        },
      },

      xAxis: [
        {
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1.1,

          tickInterval: 0.1,
          title: {
            text: null,
          },
          labels: {
            overflow: "justify",
            y: 20,
            x: 30,
          },

          gridLineColor: "#456BF1",
          gridLineWidth: 0.5,
          tickLength: 30,
          tickColor: "#456BF1",
          tickWidth: 0.5,
        },
        {
          offset: 30,

          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [0, 0.364, 0.636, 1],
          gridLineWidth: 0,
          tickWidth: 0.5,
          tickLength: 30,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
            y: 20,
            x: 100,

            formatter: function () {
              if (this.value === 0.636) {
                return `<span style="color:#1C60FF">Easy</span>`;
              } else if (this.value === 0.364) {
                return `<span style="color:#27AE7A">Medium</span>`;
              } else if (this.value === 0) {
                return `<span style="color:#E35050">Hard</span>`;
              }
            },
          },
        },
        {
          offset: 60,
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [],

          gridLineWidth: 0,
          tickWidth: 0,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
          },
        },
      ],

      yAxis: [
        {
          min: 0,
          max: 1,
          tickInterval: 0.1,
          title: {
            text: null,
          },
          labels: {
            overflow: "justify",
            y: 20,
          },

          gridLineColor: "#456BF1",
          gridLineWidth: 0.5,

          tickWidth: 0.5,
          tickLength: 40,
          tickColor: "#456BF1",
        },

        {
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },

          tickWidth: 0.5,
          tickLength: 60,
          tickColor: "#456BF1",
          tickPositions: [0, 0.2, 0.3, 0.4, 1],
          gridLineWidth: 0,

          labels: {
            overflow: "justify",
            y: 18,
            x: -5,

            formatter: function () {
              if (Math.ceil(Number(this.value) * 10) === 10) {
                return `<span style="color:#1C60FF">Excellent</span>`;
              } else if (Math.ceil(Number(this.value) * 10) === 4) {
                return `<span style="color:#27AE7A">Good</span>`;
              } else if (Math.ceil(Number(this.value) * 10) === 3) {
                return `<span style="color:#27AE7A">Fair</span>`;
              } else if (Math.ceil(Number(this.value) * 10) === 2) {
                return `<span style="color:#E35050">Poor</span>`;
              }
            },
          },
        },
        {
          offset: 100,
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [],

          gridLineWidth: 0,
          tickWidth: 0,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
          },
        },
      ],

      accessibility: {
        enabled: false,
      },

      plotOptions: {
        scatter: {
          color: "#E35050",
          dataLabels: {
            allowOverlap: true,
            enabled: true,
            verticalAlign: "middle",
            align: "center",
            borderRadius: 20,
            style: {
              textOutline: "none",
              color: "#FFFFFF",
              fontSize: 12,
              fontWeight: 400,
              borderRadius: 10,
            },
          },
        },
      },

      legend: {
        enabled: false,
        y: 20,
        align: "left",
        verticalAlign: "bottom",
        layout: "horizontal",
        symbolRadius: 0,
        itemStyle: {
          color: "#8F949F",
          fontWeight: "normal",
        },
      },

      credits: {
        enabled: false,
      },

      series: [
        // below code is for the color quadrants

        {
          type: "area",
          lineWidth: 0,
          color: "#E0EAFF",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [0.7, 0.4],
            [0.4, 0.4],
            [0.4, 0.2],
            [0.7, 0.4],
            [0.7, 0.2],
            [0.4, 0.2],
            [0.4, 0.4],
            [0.4, 0.2],
            [0.7, 0.4],
            [0.7, 0.2],
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#FFE6E6",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [0.7, 0.6], //top-right
            [0.4, 0.6], //top-left
            [0.4, 0.4], //bottom-left
            [0.7, 0.6], //top-right
            [0.7, 0.4], //bottom-right
            [0.4, 0.4], //bottom-left
            [0.4, 0.6], //top-left
            [0.4, 0.4], //bottom-left
            [0.7, 0.6], //top-right
            [0.7, 0.4], // bottom-right
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#EFEDFF",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [1, 0.7], //top-right
            [0.7, 0.7], //top-left
            [0.7, 0.4], //bottom-left
            [1, 0.7], //top-right
            [1, 0.4], //bottom-right
            [0.7, 0.4], //bottom-left
            [0.7, 0.7], //top-left
            [0.7, 0.4], //bottom-left
            [1, 0.7], //top-right
            [1, 0.4], // bottom-right
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#E0FFF3",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [1, 0.4], //top-right
            [0.7, 0.4], //top-left
            [0.7, 0.2], //bottom-left
            [1, 0.4], //top-right
            [1, 0.2], //bottom-right
            [0.7, 0.2], //bottom-left
            [0.7, 0.4], //top-left
            [0.7, 0.2], //bottom-left
            [1, 0.4], //top-right
            [1, 0.2], // bottom-right
          ],
        },

        // //////////////////////////////////////

        {
          data: outliers,

          type: "scatter",
          name: "Others",
          color: "#E35050",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q2,

          type: "scatter",
          name: "Hard",
          color: "#F86E7F",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q3,

          type: "scatter",
          name: "Easy",
          color: "#456BF1",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q1,

          type: "scatter",
          name: "2015",
          color: "#855CF8",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q4,

          type: "scatter",
          name: "Medium",
          color: "#27AE7A ",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },
      ],
    };

    return option;
  };

  renderItemAnalysisGraph = () => {
    const options = this.getGraphOptions();

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        {...this.props}
      />
    );
  };

  filteringItemAnalysis = (res) => {
    const q1 = [];
    const q2 = [];
    const q3 = [];
    const q4 = [];
    const outliers = [];

    for (let each of res) {
      if (
        each["FV"] >= 0.4 &&
        each["FV"] < 0.7 &&
        each["DI"] >= 0.2 &&
        each["DI"] < 0.4
      ) {
        q3.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.7 &&
        each["FV"] < 1 &&
        each["DI"] >= 0.2 &&
        each["DI"] < 0.4
      ) {
        q4.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.4 &&
        each["FV"] < 0.7 &&
        each["DI"] >= 0.4 &&
        each["DI"] < 0.6
      ) {
        q2.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.7 &&
        each["FV"] < 1 &&
        each["DI"] >= 0.4 &&
        each["DI"] < 0.7
      ) {
        q1.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else {
        outliers.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      }
    }
    this.setState({ itemAnalysis: { q1, q2, q3, q4, outliers } });
  };

  getItemAnalysis = async (id) => {
    try {
      const token = Cookies.get("jwt_token");

      if (id !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_COURSES_ITEM_ANALYSIS + `?courseId=${id}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.filteringItemAnalysis(response["data"]["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getCourses = async () => {
    const token = Cookies.get("jwt_token");

    try {
      const res = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=&branch=&semester=`,
        { Authorization: token }
      );

      if (res["data"]["statusCode"] === "OK") {
        const codes = res["data"]["result"]["courseDetailsResponses"].map(
          (each) => ({
            courseId: each["courseId"],
            sectionsForCourse: each["sectionsForCourse"],
          })
        );

        this.setState({
          sec: codes,
        });
      } else {
        this.setState({
          sec: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getObservation = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_ATTAINMENTS_GET_ATT_OBSERVATION +
          `?courseId=${id}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          observation: response["data"]["result"]["observationTxt"],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  apiCalls = async (id) => {
    await this.getCourses();
    await this.getPsoData();
    await this.getReportData(id);
    await this.getDesc(id);
    await this.getFbData(id);
    await this.getBlooms(id);
    await this.getPsoData();
    await this.getData(id);
    await this.getObservation(id);
    await this.getItemAnalysis(id);
  };

  downloadBtn = () => {
    window.print();
  };

  renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        No Course Outcome Attainment Data Found To Generate The Report
      </p>
    </div>
  );

  triggerTable = () => {
    this.tableStruct();
    // this.getPsoData();
  };

  getData = async (id) => {
    try {
      const { sec } = this.state;
      const token = Cookies.get("jwt_token");
      let secVal = "";
      secVal = sec.filter((each) => `${each.courseId}` === `${id}`);

      const response = await apiClient.get(
        apiClient.urls.fatcat.COURSE_ANALYSIS +
          `?courseId=${id}&sectionList=${secVal[0]["sectionsForCourse"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (Object.keys(response["data"]["result"]["coAttData"]).length > 0) {
          this.setState(
            {
              res: response["data"]["result"],
              loading: true,
              customTxt:
                response?.["data"]?.["result"]?.["courseDetails"]?.[
                  "courseCode"
                ] ?? "",
            },
            this.triggerTable
          );

          // console.log(response['data']['result']);
        } else {
          this.setState({
            res: response["data"]["result"],
            loading: false,
            customTxt:
              response?.["data"]?.["result"]?.["courseDetails"]?.[
                "courseCode"
              ] ?? "",
          });
        }
      } else {
        this.setState({ res: [], loading: false, customTxt: "" });
      }

      // console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  getReportData = async (id) => {
    try {
      const { branch } = this.props.match.params;

      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.PDF_DATA + `?courseId=${id}&branch=${branch}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ pdfData: response["data"]["result"] });
      } else {
        this.setState({ pdfData: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getPsoData = async () => {
    const { batch, branch, semester, id } = this.props.match.params;

    try {
      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.PO_PSO_ATTAINMENT +
          `?batch=${batch}&branch=${branch}&semester=${semester}&courseId=${id}`,
        { Authorization: token }
      );

      // console.log(response['data']);
      if (response["data"]["statusCode"] === "OK") {
        this.setState({ psoData: response["data"]["result"] });
      } else {
        this.setState({ psoData: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBlooms = async (id) => {
    try {
      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_ART_MATRIX + `?courseId=${id}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ art: response["data"]["result"] });

        // console.log(response['data']['result']);
      } else {
        this.setState({ art: [] });
      }

      // console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  tableStruct = () => {
    const { res } = this.state;
    try {
      const { courseStructure } = res;

      const item = courseStructure;

      const intTheory =
        item.intTheoryShortList === null
          ? ""
          : item.intTheoryShortList.split(",");

      const intPract =
        item.intPractShortList === null
          ? ""
          : item.intPractShortList.split(",");

      const intProj =
        item.intProjShortList === null ? "" : item.intProjShortList.split(",");

      const internal = intTheory.concat(intPract).concat(intProj);

      const internalValues = [];

      for (const each of internal) {
        if (each !== "") {
          internalValues.push(each);
        }
      }

      const extTheory =
        item.extTheoryShortList === null
          ? ""
          : item.extTheoryShortList.split(",");

      const extPract =
        item.extPractShortList === null
          ? ""
          : item.extPractShortList.split(",");
      const extProj =
        item.extProjShortList === null ? "" : item.extProjShortList.split(",");

      const external = extTheory.concat(extPract).concat(extProj);

      const externalValues = [];

      for (const each of external) {
        if (each !== "") {
          externalValues.push(each);
        }
      }
      this.setState({
        internalData: internalValues,
        externalData: externalValues,
      });
    } catch (err) {
      console.log(err);
    }
  };

  renderCoAtt = () => {
    const { res, internalData, externalData, customTxt } = this.state;
    const data = res;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr
            className={`${styles["table-th"]} border border-blue-500 text-[#040C58] text-sm `}
          >
            <th className="border border-blue-500 py-1 text-sm">
              {customTxt}.{index + 1}
            </th>

            <>
              {internalData.map((e) => (
                <td
                  key={e + index}
                  className={` text-sm text-center border border-blue-500 py-1 text-[#606981]`}
                >
                  {`${
                    data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]?.map(
                      (each) => {
                        if (
                          each["assmntCat"] === `Internal` &&
                          each["templateName"] === e
                        ) {
                          return each["pctGtThValue"];
                        }

                        return "";
                      }
                    ) ?? ""
                  }`.replaceAll(",", "")}
                </td>
              ))}
            </>

            <>
              {externalData.map((e) => (
                <td
                  key={e + index}
                  className={` text-sm text-center border border-blue-500 py-1 text-[#606981]`}
                >
                  {`${
                    data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]?.map(
                      (each) => {
                        if (
                          each["assmntCat"] === `External` &&
                          each["templateName"] === e
                        ) {
                          return each["pctGtThValue"];
                        }

                        return "";
                      }
                    ) ?? ""
                  }`.replaceAll(",", "")}
                </td>
              ))}
            </>

            <td
              className={` text-sm border border-blue-500 text-center py-1 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data?.["coAttData"])[index]]?.[
                "avgCie"
              ] ?? ""}
            </td>
            <td
              className={` text-sm text-center border border-blue-500 py-1 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data?.["coAttData"])[index]]?.[
                "wAvgCie"
              ] ?? ""}
            </td>
            <td
              className={` text-sm text-center border border-blue-500 py-1 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wAvgSee"
              ] ?? ""}{" "}
            </td>

            <th className={` text-sm text-center border border-blue-500 py-1`}>
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalAttDaPct"
              ] ?? ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderOverAllAtt = () => {
    const { res, customTxt } = this.state;
    const data = res;

    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr
            key={index}
            className={`${styles["table-th"]} text-center  text-sm border border-blue-500 text-[#040C58]`}
          >
            <th className="text-sm border border-blue-500 py-1">
              {customTxt}.{index + 1}
            </th>
            <td
              className={` text-sm border border-blue-500 text-[#606981] py-1`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalAttDaPct"
              ] ?? ""}
            </td>
            <td
              className={`py-1  text-sm border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wTotalAttDaPct"
              ] ?? ""}
            </td>
            <td
              className={`py-1  text-sm border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "coAttIdaPct"
              ] ?? ""}
            </td>

            <td
              className={`py-1  text-sm border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wTotalAttIdaPCt"
              ] ?? ""}
            </td>

            <th className={`py-1  text-sm border border-blue-500`}>
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalCoAtt"
              ] ?? ""}
            </th>
            <td
              className={`py-1  text-sm border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "targetAtt"
              ] ?? ""}
            </td>

            <td
              className={`py-1  text-sm border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "coAttLvl"
              ] ?? ""}
            </td>
            <th
              className={`py-1  ${styles["table-td"]} ${
                data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                  "isCoAchieved"
                ] === "Y"
                  ? "text-green-500"
                  : "text-red-500"
              } border border-blue-500`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "isCoAchieved"
              ] ?? ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getIntTd = () => {
    const { internalData } = this.state;
    const ans = [];
    try {
      for (const each of internalData) {
        ans.push(
          <th
            className={`${styles["table-th"]} border border-blue-500 p-0 py-1 text-[#040C58] bg-[#EFF3FF] text-sm`}
          >
            {each}
          </th>
        );
      }
      // console.log(ans);

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getExtTd = () => {
    const { externalData } = this.state;

    const ans = [];

    try {
      for (const each of externalData) {
        ans.push(
          <th
            className={`${styles["table-th"]} border border-blue-500 p-0 py-1 text-[#040C58] bg-[#EFF3FF] text-sm`}
          >
            {each}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderPsoData = () => {
    const { psoData, res } = this.state;
    const data = res;

    try {
      return (
        <tr
          className={`${styles["table-th"]} border border-blue-500 text-[#040C58] `}
        >
          <th className="border border-blue-500 p-1  text-sm">
            {data["courseDetails"]["courseShortName"]}
          </th>
          <td className={`border border-blue-500 p-1 text-[#606981]  text-sm`}>
            {psoData?.[0]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[1]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[2]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[3]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[4]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[5]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[6]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[7]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[8]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[9]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[10]?.["poAttvalue"] ?? ""}
          </td>
          <td className={` border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[11]?.["poAttvalue"] ?? ""}
          </td>
          {/* {this.renderDynamicPsosValues()} */}
        </tr>
      );
    } catch (err) {
      console.log(err);
    }
  };

  renderDynamicPsosValues = () => {
    const { psoData, res } = this.state;
    const data = res;
    try {
      const ans = [
        <th className="border border-blue-500 p-1  text-sm">
          {data["courseDetails"]["courseShortName"]}
        </th>,
      ];

      for (let index = 12; index < psoData.length; index++) {
        ans.push(
          <td className={`border border-blue-500 p-1 text-[#606981] text-sm`}>
            {psoData?.[index]?.["poAttvalue"] ?? ""}
          </td>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderDynamicPsos = () => {
    const { psoData } = this.state;
    const ans = [];
    try {
      for (let index = 12; index < psoData.length; index++) {
        ans.push(
          <th className="border border-blue-500 p-1 text-sm">
            {psoData?.[index]?.["poPsoName"]}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  questionMapping = () => {
    const { res, customTxt } = this.state;
    const data = res;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr
            className={`${styles["table-th"]} border border-blue-500 text-[#040C58] `}
          >
            <th className="border border-blue-500 p-1 text-sm">
              {customTxt}.{index + 1}
            </th>
            <td className={`border border-blue-500 p-1 text-[#606981] text-sm`}>
              {
                data["coAttData"][Object.keys(data["coAttData"])[index]][
                  "questnNameList"
                ]
              }
            </td>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  poDescription = () => {
    const { pdfData } = this.state;
    const ans = [];
    try {
      for (const each of pdfData) {
        ans.push(
          <tr
            className={`${styles["table-th"]} border border-blue-500 text-[#040C58] `}
          >
            <th className="border border-blue-500 p-1 text-sm w-1/12">
              {each?.["po"] ?? ""}
            </th>
            <td
              className={`text-start border border-blue-500 p-1 text-[#606981] text-sm w-11/12`}
            >
              {each?.["poDesc"] ?? ""}
            </td>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderPos = () => {
    const { res, art, psos, customTxt } = this.state;
    const data = res;
    const ans = [];
    try {
      for (
        let index = 1;
        index <= data["courseDetails"]["numberOfCourseOutcomes"];
        index++
      ) {
        const posList = [];

        for (let each = 1; each <= 12; each++) {
          const res = art.filter(
            (item) =>
              item.coName === `CO${index}` && item.poName === `PO${each}`
          );
          posList.push(
            <td
              className={`${styles["labels"]} text-center  text-sm border border-blue-500 p-0 py-1`}
            >
              {res.length !== 0
                ? res[0]["weightage"] === 0
                  ? "-"
                  : res[0]["weightage"]
                : ""}
            </td>
          );
        }

        for (let each = 1; each <= psos; each++) {
          const res = art.filter(
            (item) =>
              item.coName === `CO${index}` && item.psoName === `PSO${each}`
          );
          posList.push(
            <td
              className={`${styles["labels"]} text-center border border-blue-500 p-0 py-1 text-sm`}
            >
              {res.length !== 0
                ? res[0]["weightage"] === 0
                  ? "-"
                  : res[0]["weightage"]
                : ""}
            </td>
          );
        }

        ans.push(
          <>
            <tr className="text-[#040C58] text-center border border-t-0 border-l-0 border-blue-500">
              <th
                className={`${styles["labels"]} text-center border border-blue-500 p-0 py-1 text-sm`}
              >
                {customTxt}.{index}
              </th>

              {posList}
            </tr>
          </>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderDynamicPso = () => {
    const { psos } = this.state;
    const ans = [];
    try {
      for (let each = 1; each <= psos; each++) {
        ans.push(
          <th scope="col" className="border border-blue-500 p-0 py-1">
            PSO{each}
          </th>
        );
      }
    } catch (err) {
      console.log(err);
    }
    return ans;
  };

  renderData = () => {
    const {
      internalData,
      externalData,
      loading,
      res,
      userType,
      observation,
      customTxt,
    } = this.state;
    const details = res["courseDetails"];

    const noCos = details["numberOfCourseOutcomes"];
    const cat = [];

    for (let index = 1; index <= noCos; index++) {
      cat.push(`${details["courseCode"]}.${index}`);
    }

    const ta = [];

    for (let index = 1; index <= noCos; index++) {
      ta.push(res?.["coAttData"]?.[`CO${index}`]?.["targetAtt"]);
    }

    const tca = [];

    for (let index = 1; index <= noCos; index++) {
      tca.push(res?.["coAttData"]?.[`CO${index}`]?.["totalCoAtt"]);
    }

    const options = {
      chart: {
        type: "column",
        width: 700,
        height: 280,
      },

      title: {
        text: "",
      },

      xAxis: {
        categories: cat,
        title: {
          text: null,
        },
        labels: {
          overflow: "justify",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
        labels: {
          overflow: "justify",
          formatter: function () {
            return this.value + "%";
          },
        },
      },
      tooltip: {
        valueSuffix: "%",
      },
      accessibility: {
        enabled: false,
      },

      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
        series: {
          // pointPadding: 0,
          // borderRadius: 3,
        },
      },

      legend: {
        // y: 20,
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        symbolRadius: 0,
        itemStyle: {
          color: "#000000",
          fontWeight: "normal",
        },
        enabled: true,
      },

      credits: {
        enabled: false,
      },
      series: [
        {
          data: ta,
          type: "column",
          name: "Target Attainment",
          color: "red",
        },
        {
          data: tca,
          type: "column",
          name: "Total CO Attainment",
          color: "blue",
        },
      ],
    };

    return (
      <>
        {loading && (
          <div className="flex justify-end mr-5">
            <button
              onClick={this.downloadBtn}
              className={`${styles["download-btn"]} font-Lato-normal flex justify-center items-center text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mr-5 mt-4`}
              type="submit"
            >
              Download Report
            </button>
          </div>
        )}
        <div id="pdf1" className={`${styles["page"]} pt-5 w-full`}>
          <p className="capitalize font-bold text-xl text-[#606981] text-center mt-2">
            {userType["clg_name"]}
          </p>
          <p className=" text-lg text-[#606981] text-center mt-0">
            Department of {res["courseDetails"]["branch"]}
          </p>
          <p className=" text-lg text-[#606981] text-center mt-1 pb-2 mb-2">
            Course Outcome Attainment
          </p>

          <div className="mt-2 mb-2 pb-2 mb-4 flex items-center">
            <span>
              <p className="text-sm text-[#606981]">Course Code:</p>
              <p className="text-sm text-[#606981]">Course Name:</p>
              <p className="text-sm text-[#606981]">Semester:</p>
              <p className="text-sm text-[#606981]">Regulation:</p>
              <p className="text-sm text-[#606981]">Batch:</p>
              <p className="text-sm text-[#606981]">Faculty Name:</p>
            </span>
            <span className="ml-8">
              <p className="text-sm text-[#606981]">
                <input
                  type="text"
                  value={customTxt}
                  onChange={(e) => {
                    this.setState({ customTxt: e.target.value });
                  }}
                  className={styles["custom-txt"]}
                />
                &nbsp;
                <span className="print:hidden italic text-[#9fb2f6]">
                  &larr; Modify this value to update the Course Outcome (CO)
                  names listed below.
                </span>
              </p>
              <p className="text-sm text-[#606981]">{details["courseName"]}</p>
              <p className="text-sm text-[#606981]">{details["semester"]}</p>
              <p className="text-sm text-[#606981]">
                {details["regulationName"]}
              </p>
              <p className="text-sm text-[#606981]">{details["batch"]}</p>
              <p className="text-sm text-[#606981]">{details["facultyName"]}</p>
            </span>
          </div>
          {/* course Outcomes */}
          <div className="flex flex-col justify-start flex-wrap text-[#636363] pb-0 pt-0 mt-8">
            <p className="text-sm text-[#606981] text-center pb-2 mb-2">
              Course Outcomes (COs)
            </p>
            <table className="self-start text-center border border-blue-500 w-full">
              <tbody>
                <tr
                  className={`${styles["table-th"]} text-sm border border-blue-500 p-0 w-full text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-0 py-2 text-sm">
                    Course Outcomes
                  </th>
                  <th className="border border-blue-500 p-0 py-2 text-sm">
                    CO Statement
                  </th>
                </tr>

                {this.renderCourseOutcomes()}
              </tbody>
            </table>
          </div>

          <p className="text-sm text-[#606981] text-center mt-6 mb-4">
            Course Articulation Matrix (CO-PO Mapping)
          </p>

          <div className="flex flex-col mt-4 w-full overflow-x-auto">
            <table className="text-center border border-blue-500">
              <tbody>
                <tr
                  className={`${styles.matrix} border border-blue-500 p-0 w-full`}
                >
                  <th className="border border-blue-500 p-0 py-1">CO/PO</th>

                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO1
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO2
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO3
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO4
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO5
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO6
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO7
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO8
                  </th>
                  <th
                    scope="col"
                    className="border border-blue-500 p-0 py-1 px-1"
                  >
                    PO9
                  </th>
                  <th scope="col" className="border border-blue-500 p-0 py-1">
                    PO10
                  </th>
                  <th scope="col" className="border border-blue-500 p-0 py-1">
                    PO11
                  </th>
                  <th scope="col" className="border border-blue-500 p-0 py-1">
                    PO12
                  </th>
                  {this.renderDynamicPso()}
                </tr>
              </tbody>
              <tbody>{this.renderPos()}</tbody>
            </table>
          </div>
        </div>

        <div id="pdf2" className={`${styles["page"]} pt-5 w-full`}>
          {/* Question Mapping */}
          <p className=" text-sm text-[#606981] text-center mt-6">
            Course Outcomes And Question Grouping
          </p>

          <div className="flex flex-col mt-2 mb-10 w-full overflow-x-auto">
            <table className="text-center border border-blue-500">
              <tbody>
                <tr
                  className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-1 text-sm">
                    Course Outcome
                  </th>
                  <th className="border border-blue-500 p-1 text-sm">
                    Question Numbers
                  </th>
                </tr>
                {this.questionMapping()}
              </tbody>
            </table>
          </div>

          <div className="flex justify-center w-full mb-3">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>

          <div className="flex justify-center w-full">
            {this.renderItemAnalysisGraph()}
          </div>
        </div>

        <div id="pdf3" className={`${styles["page"]} pt-5 w-full`}>
          <p className=" text-sm text-[#606981] text-center mt-6 pb-2 mb-1">
            Attainment Of Course Outcomes (Cos) Through Direct Assessment (DA)
            Tools
          </p>

          <div className={styles.table}>
            <div
              className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
            >
              <table className="mb-2 border-solid border-blue-600 border-1">
                <tbody>
                  <tr
                    className={`${styles["table-th"]} border-0 border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                  >
                    <th
                      className="border border-blue-500 py-1 text-sm"
                      rowSpan={2}
                    >
                      CO
                    </th>
                    {internalData.length !== 0 && (
                      <th
                        className="border border-blue-500 p-0 pb-1 border-0 text-sm"
                        colSpan={internalData.length}
                      >
                        Internal
                      </th>
                    )}

                    {externalData.length !== 0 && (
                      <th
                        className="border border-blue-500 p-0 pb-1 text-sm"
                        colSpan={externalData.length}
                      >
                        External
                      </th>
                    )}
                    <th
                      className="border border-blue-500 p-0 py-1 text-sm"
                      rowSpan={2}
                    >
                      Avg <br /> Of CIE
                    </th>
                    {this.renderCiewSeew()}

                    <th
                      className="border border-blue-500 p-0 py-1 text-sm"
                      rowSpan={2}
                    >
                      TA Through <br /> DA (100%)
                    </th>
                  </tr>
                  <tr className="text-center text-sm">
                    {internalData.length !== 0 && this.getIntTd()}
                    {externalData.length !== 0 && this.getExtTd()}
                  </tr>

                  {this.renderCoAtt()}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col justify-start flex-wrap text-[#636363] pb-0 pt-0 mt-6">
            <p className=" text-sm text-[#606981] text-center pb-2 mb-2">
              Attainment of Course Outcomes (CO) through Indirect Assessment(IA)
              Tools
            </p>
            <table className="text-center border border-blue-500 w-full">
              <tbody>
                <tr
                  className={`${styles["table-th"]} border border-blue-500 p-0 w-full text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-0 py-1 text-sm">
                    Course Outcomes
                  </th>
                  <th className="border border-blue-500 p-0 py-1 text-sm">
                    CO Statement
                  </th>
                  <th className="border border-blue-500 p-0 py-1 text-sm">
                    Course End Survey
                  </th>
                </tr>

                {this.renderOutcomes()}
              </tbody>
            </table>
          </div>

          <p className=" text-sm text-[#606981] text-center mt-6 pb-2 mb-2">
            Overall Attainment Of Course Outcomes (Co) Through Direct And
            Indirect Assessment Tools:
          </p>

          <div className={styles.table}>
            <div
              id="oAscrollTb"
              className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
            >
              <table className="text-center border border-blue-500">
                <tbody>
                  <tr
                    className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                  >
                    <th className="border border-blue-500 py-1 text-sm">CO</th>
                    <th className="border border-blue-500 py-1 text-sm">
                      CO ATT <br /> (DA)
                    </th>
                    {this.renderDAw()}
                    <th className="border border-blue-500 py-1 text-sm">
                      CO ATT <br />
                      (IA)
                    </th>
                    {this.renderIAw()}
                    <th className="border border-blue-500 py-1 text-sm">
                      Total <br />
                      CO ATT
                    </th>
                    <th className="border border-blue-500 py-1 text-sm">
                      Target
                    </th>
                    <th className="border border-blue-500 py-1 text-sm">
                      ATT <br />
                      Lvl
                    </th>
                    <th className="border border-blue-500 py-1 text-sm">
                      ATT <br /> Achieved
                    </th>
                  </tr>

                  {this.renderOverAllAtt()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div id="pdf4" className={`pt-5 w-full`}>
          <p className=" text-sm text-[#606981] text-center mt-6 pb-2 mb-2">
            Direct Program Outcomes Attainment
          </p>

          <div className={`${styles.table} pb-3`}>
            <div
              id="poscrollTb"
              className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
            >
              <table className="text-center border border-blue-500 ">
                <tbody>
                  <tr
                    className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                  >
                    <th className="border border-blue-500 p-1 text-sm">
                      Course Name
                    </th>
                    <th className="border border-blue-500 p-1 text-sm">PO1</th>
                    <th className="border border-blue-500 p-1 text-sm">PO2</th>
                    <th className="border border-blue-500 p-1 text-sm">PO3</th>
                    <th className="border border-blue-500 p-1 text-sm">PO4</th>
                    <th className="border border-blue-500 p-1 text-sm">PO5</th>
                    <th className="border border-blue-500 p-1 text-sm">PO6</th>
                    <th className="border border-blue-500 p-1 text-sm">PO7</th>
                    <th className="border border-blue-500 p-1 text-sm">PO8</th>
                    <th className="border border-blue-500 p-1 text-sm">PO9</th>
                    <th className="border border-blue-500 p-1 text-sm">PO10</th>
                    <th className="border border-blue-500 p-1 text-sm">PO11</th>
                    <th className="border border-blue-500 p-1 text-sm">PO12</th>
                    {/* {this.renderDynamicPsos()} */}
                  </tr>
                  {this.renderPsoData()}
                </tbody>
              </table>
            </div>
          </div>
          <p className=" text-sm text-[#606981] text-center mt-1 pb-2 mb-2">
            Direct Program-Specific Outcome Attainment
          </p>
          <div className={`${styles.table} pb-5`}>
            <div
              id="poscrollTb"
              className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
            >
              <table className="text-center border border-blue-500 ">
                <tbody>
                  <tr
                    className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                  >
                    <th className="border border-blue-500 p-1 text-sm">
                      Course Name
                    </th>
                    {this.renderDynamicPsos()}
                  </tr>
                  {/* {this.renderPsoData()} */}
                  {this.renderDynamicPsosValues()}
                </tbody>
              </table>
            </div>
          </div>

          <p className=" text-sm text-[#606981] mt-2 text-center">
            Program Outcomes (POs) And Program Specific Outcomes (PSOs)
          </p>

          <div className="flex flex-col mt-4 w-full overflow-x-auto">
            <table className="text-center border border-blue-500">
              <tbody>
                <tr
                  className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-1 text-sm">PO #</th>
                  <th className="border border-blue-500 p-1 text-sm">
                    PO Description
                  </th>
                </tr>
                {this.poDescription()}
              </tbody>
            </table>
          </div>

          <p
            className={`${observation === "" ? "hidden" : "flex"} ${
              styles["comments"]
            } text-sm text-[#606981] mt-8`}
          >
            COMMENTS
          </p>
          <p className="mt-1">{observation}</p>
          <div
            className={`hidden ${styles["comments"]} flex ${
              observation === "" ? "mt-52" : "mt-20"
            }`}
          >
            <p className="text-sm text-[#606981]">COURSE LEAD</p>
            <p className="text-sm text-[#606981]">PROGRAM CO-ORDINATOR</p>
          </div>
        </div>
        <p
          className={`hidden ${styles["comments"]} font-light text-xs text-[#606981] mt-10`}
        >
          A Report by Fatcat Innovations Private Limited
        </p>
      </>
    );
  };

  renderView = () => {
    const { loading } = this.state;
    try {
      switch (loading) {
        case true:
          return this.renderData();
        case false:
          return this.renderEmptyView();
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderCourseOutcomes = () => {
    const { res, desc, customTxt } = this.state;
    const data = res["courseDetails"];
    const ans = [];
    try {
      for (let index = 1; index <= data["numberOfCourseOutcomes"]; index++) {
        ans.push(
          <tr className="border border-blue-500 text-[#606981] text-sm p-0">
            <th
              className={`${styles["table-th"]} text-[#040C58] border border-blue-500 p-0 px-2 py-1 text-sm`}
            >
              {customTxt}.{index}
            </th>
            <th
              className={`${styles["table-th"]} border border-blue-500 p-0 px-2 py-1 text-sm`}
            >
              <p>{desc[index - 1]["coDesc"]}</p>
            </th>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  renderOutcomes = () => {
    const { res, desc, fb, customTxt } = this.state;
    const data = res["courseDetails"];
    const ans = [];
    try {
      for (let index = 1; index <= data["numberOfCourseOutcomes"]; index++) {
        ans.push(
          <tr
            className={`${styles["table-th"]} border border-blue-500 text-[#606981] p-0`}
          >
            <th
              className={`text-[#040C58] text-sm border border-blue-500 p-0 px-1 py-1`}
            >
              {customTxt}.{index}
            </th>
            <th className="text-sm border border-blue-500 p-0 px-1 py-1">
              <p>{desc[index - 1]["coDesc"]}</p>
            </th>
            <th className="text-sm border border-blue-500 p-0 px-1 py-1">
              <p>{fb.length !== 0 ? fb[index - 1]["feedbackValue"] : null}</p>
            </th>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  getDesc = async (id) => {
    const token = Cookies.get("jwt_token");

    const response = await apiClient.get(
      apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION + `?courseId=${id}`,
      { Authorization: token }
    );

    if (response["data"]["statusCode"] === "OK") {
      this.setState({ desc: response["data"]["result"] });
    } else {
      this.setState({ desc: [] });
    }
  };

  getFbData = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FB_VALUES + `?courseId=${id}`,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        this.setState({ fb: response["data"]["result"] });
      } else {
        this.setState({ fb: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderCiewSeew = () => {
    const { res } = this.state;

    const ans = [];
    try {
      ans.push(
        <>
          <th className="border border-blue-500 p-2" rowSpan={2}>
            {100 * res["courseWeightages"]["cieW"]}% of <br />
            CIE
          </th>
          <th className="border border-blue-500 p-2" rowSpan={2}>
            {100 * res["courseWeightages"]["seeW"]}% of <br />
            SEE
          </th>
        </>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderDAw = () => {
    const { res } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {100 * res["courseWeightages"]["directCoW"]}% of DA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderIAw = () => {
    const { res } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {100 * res["courseWeightages"]["indirectCoW"]}% of IA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return <div className={styles["pdf-bg"]}>{this.renderView()}</div>;
  }
}

export default Pdf;
