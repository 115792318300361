import { Component } from "react";
import InitialSetup from "../../InitialSetup";
import SideBar from "../../layouts/SideBar";
import ObeNavBar from "../../layouts/ObeNavBar";
import PerformanceAnalysis from "./PerformanceAnalysis";
import "./index.css";
import "../../LoginForm/index.css";
import FiltersContext from "../../../context/FiltersContext";

class StudentsPage extends Component {
  state = { setUp: false };

  static contextType = FiltersContext;

  componentDidMount() {
    const { filtersBatch } = this.context;
    this.setState({ setUp: filtersBatch.length > 0 });
  }

  renderView = () => {
    const { setUp } = this.state;
    try {
      switch (setUp) {
        case true:
          return (
            <div className="flex flex-col min-h-full overflow-y-hidden ml-1">
              <PerformanceAnalysis />
            </div>
          );
        case false:
          return <InitialSetup />;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div className="home flex flex-row overflow-hidden bg-[#FFFFFF]">
          <ObeNavBar />
          <div className="flex justify-between w-[100%] h-screen">
            <span className="w-[12%]">
              <SideBar />
            </span>
            <div className="bg-[#fff] w-[88%] relative h-screen overflow-y-auto">
              {this.renderView()}
            </div>
          </div>
        </div>
      </span>
    );
  }
}

export default StudentsPage;
