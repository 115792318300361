import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { flushSync } from "react-dom";

const Students = (props) => {
  const [loader, updateLoader] = useState(false);
  const [data, updateData] = useState([]);
  const [pagesCount, updatePagesCount] = useState(1);
  const [page, updatePage] = useState(1);

  const [isDownloading, setIsDownloading] = useState(false);

  const { batch, branch, courseCode } = props;

  const getStudentsData = async () => {
    updateLoader(true);
    updateData([]);

    if (batch !== "" && branch !== "" && courseCode !== "") {
      const token = Cookies.get("jwt_token");

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let queryParams = `?batchId=${batch}&branch=${branch}&courseId=${courseCode}`;

      try {
        const response = await fetch(
          apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_CO_ATTAINMENTS +
            queryParams,
          options
        );

        if (response.ok) {
          const data = await response.json();

          updateData(data);
        }
      } catch (err) {
        console.log(err);
      }
    }

    updateLoader(false);
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>No student data found</p>
    </div>
  );

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  const renderCoAttPct = (item) => {
    const result = [];

    for (let i = 1; i <= Math.min(data?.[0]?.["no_of_cos"], 6); i++) {
      result.push(
        <td
          className={`text-${
            item?.["cos"]?.[`CO${i}`] >= 50 ? "[#27AE7A]" : "[#E35050]"
          } text-md`}
        >
          {item?.["cos"]?.[`CO${i}`] ?? "-"}
          {item?.["cos"]?.[`CO${i}`] && "%"}
        </td>
      );
    }

    return result;
  };

  const renderCoHeaders = () => {
    const result = [];

    for (let i = 1; i <= Math.min(data?.[0]?.["no_of_cos"], 6); i++) {
      result.push(<th>CO{i}</th>);
    }

    return result;
  };

  const downPdf = async () => {
    setIsDownloading(true);

    updatePage(1);

    const pdf = new jsPDF("p", "mm", "a4");

    try {
      for (let i = 1; i <= pagesCount; i++) {
        flushSync(() => {
          updatePage(i); // Force synchronous state update
        });

        const pageContent = document.getElementById("stdAttListPdf");
        const canvas = await html2canvas(pageContent);
        const clgDataImg = canvas.toDataURL("image/png");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = pdf.internal.pageSize.getHeight();

        // Adjust the size and position of the image on the PDF page
        const imgWidth = 180; // A4 paper width
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const xPos = (pdfWidth - imgWidth) / 1.8;
        // const yPos = (pdfHeight - imgHeight) / 2;

        pdf.addImage(clgDataImg, "PNG", xPos, 10, imgWidth, imgHeight);

        i < pagesCount && pdf.addPage();
      }

      pdf.save("Report.pdf");
    } catch (error) {
      console.error("Error converting HTML to PDF:", error);
    }

    setIsDownloading(false);
  };

  const pageChange = (event) => {
    if (event.target.name === "plus") {
      page < pagesCount && updatePage(page + 1);
    } else {
      page > 1 && updatePage(page - 1);
    }
  };

  const renderStudentAttList = (item) => (
    <tr className="text-[#606981]">
      <td>{item.roll_num}</td>
      <td>
        {item.first_name} {item.last_name}
      </td>
      {renderCoAttPct(item)}
      <td className={`text-${item.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"}`}>
        {item.total_cos_attained}/{item.total_cos}
      </td>
      <td className={`text-${item.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"}`}>
        {item.avg_att * 1}%
      </td>
    </tr>
  );

  useEffect(() => {
    getStudentsData();
  }, [batch, branch, courseCode]);

  useEffect(() => {
    const len = data.length;
    const pages = Math.ceil(len / 30);
    updatePagesCount(pages === 0 ? 1 : pages);
    updatePage(1);
  }, [data]);

  return (
    <div className={styles["bg-containers"]}>
      <span className="flex justify-end items-center mt-2 mb-2">
        {data.length > 0 && !isDownloading && (
          <button
            onClick={downPdf}
            className={`${styles["table-btns"]} flex items-center mr-8`}
            type="button"
          >
            <img src="/assets/download.svg" alt="add" className="mr-2" />
            Download
          </button>
        )}

        <div className="xl:w-2/12 flex justify-between items-center">
          <button
            name="minus"
            onClick={pageChange}
            className={styles["arrow-buttons-table"]}
          >
            <img
              name="minus"
              src="/assets/left-arr-outline.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </button>
          <p className={styles["table-list-count"]}>
            {page} of {pagesCount}
          </p>
          <button
            name="plus"
            onClick={pageChange}
            className={`${styles["arrow-buttons-table"]} ${styles["right-btn"]}`}
          >
            <img
              name="plus"
              src="/assets/left-arr-outline.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </button>
        </div>
      </span>

      <table id="stdAttListPdf" className={styles["students-table-container"]}>
        <tr>
          <th>Student ID</th>
          <th>Name</th>
          {renderCoHeaders()}
          <th>Outcomes</th>
          <th>Avg Attainment</th>
        </tr>

        {data
          .slice((page - 1) * 30, page * 30)
          .map((item) => renderStudentAttList(item))}
      </table>
      {loader ? renderLoader() : data.length === 0 && renderEmptyView()}
    </div>
  );
};

export default Students;
