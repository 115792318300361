import styles from "./index.module.css";
import { useState, useEffect, useContext } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import FiltersContext from "../../../../../../context/FiltersContext";

const buttonValues = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const CourseArticulationMatrix = (props) => {
  const {
    branch,
    statusCode,
    metricInfo,
    metricsId,
    uploadedStatus,
    template,
    academicYear,
    statusChangeBtn,
  } = props;

  const { filtersBatch } = useContext(FiltersContext);

  const [semButton, updateSemButton] = useState(Object.keys(buttonValues)[0]);
  const [poData, updatePoData] = useState([]);
  const [psoData, updatePsoData] = useState([]);

  const [rowsCount1, setRowsCount1] = useState(0);
  const [rowsCount2, setRowsCount2] = useState(0);
  const [filtData, updateFiltData] = useState({});
  const [filtArtData, updateFiltArtData] = useState({});
  const [coursesData, updateCoursesData] = useState([]);
  const [isCourse, setIsCourse] = useState(false);
  const [desc, updateDesc] = useState([]);
  const [desc2, updateDesc2] = useState([]);
  const [art, updateArt] = useState([]);
  const [art2, updateArt2] = useState([]);
  const [courseIdList, setCourseIdList] = useState([]);

  const submitted1 = async () => {
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const coData = filtData?.["1"] ?? [];

    try {
      for (let [count, each] of coData.entries()) {
        [...poData, ...psoData].forEach((item) => {
          reqBody.push({
            courseArtId: document
              .getElementById(`${item["po"]}-1-${count + 1}-${semButton}`)
              .getAttribute("data-coartid"),
            tableNum: "1",
            courseCode: filtData?.["1"]?.[0]?.["course_code"] ?? "",
            courseName: filtData?.["1"]?.[0]?.["course_name"] ?? "",
            semester: semButton,
            coName: each["co_name"],
            poName: item["po"],
            poLevel: document.getElementById(
              `${item["po"]}-1-${count + 1}-${semButton}`
            ).value,
            courseOutcomeId: document.getElementById(
              `${item["po"]}-1-${count + 1}-${semButton}`
            ).name,
          });
        });
      }

      const options = {
        method: !filtArtData?.["1"] ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const submitted2 = async () => {
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const coData = filtData?.["2"] ?? [];

    try {
      for (let [count, each] of coData.entries()) {
        [...poData, ...psoData].forEach((item) => {
          reqBody.push({
            courseArtId: document
              .getElementById(`${item["po"]}-2-${count + 1}-${semButton}`)
              .getAttribute("data-coartid"),
            tableNum: "2",
            courseCode: filtData?.["2"]?.[0]?.["course_code"] ?? "",
            courseName: filtData?.["2"]?.[0]?.["course_name"] ?? "",
            semester: semButton,
            coName: each["co_name"],
            poName: item["po"],
            poLevel: document.getElementById(
              `${item["po"]}-2-${count + 1}-${semButton}`
            ).value,
            courseOutcomeId: document.getElementById(
              `${item["po"]}-2-${count + 1}-${semButton}`
            ).name,
          });
        });
      }

      const options = {
        method: !filtArtData?.["2"] ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const clickedSemBtn = (event) => {
    updateSemButton(event.target.value);
    setCourseIdList([]);
    updateFiltArtData({});
    updateFiltData({});
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${branch}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updatePsoData(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCourses = async () => {
    const token = Cookies.get("jwt_token");

    updateCoursesData([]);

    try {
      const batch = filtersBatch.filter(
        (each) => each?.["optionDesc"] === academicYear
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=${batch[0]?.["optionValue"]}&branch=${branch}&regulation=&semester=${semButton}&section=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateCoursesData(
          response?.["data"]?.["result"]?.["courseDetailsResponses"] ?? []
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDescCo = async (courseId, table) => {
    table === "1" ? updateDesc([]) : updateDesc2([]);

    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_ART_MATRIX + `?courseId=${courseId}`,
        { Authorization: token }
      );

      const responseDesc = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION +
          `?courseId=${courseId}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        table === "1"
          ? updateArt(response["data"]["result"])
          : updateArt2(response["data"]["result"]);
      }

      if (responseDesc["data"]["statusCode"] === "OK") {
        table === "1"
          ? updateDesc(responseDesc["data"]["result"])
          : updateDesc2(responseDesc["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderButtonOptions = () => {
    return (
      <>
        {Object.entries(buttonValues).map(([key, value]) => (
          <button
            key={key}
            value={key}
            className={`${
              semButton === key
                ? styles["sem-button-active"]
                : styles["sem-button"]
            }`}
            onClick={clickedSemBtn}
          >
            {value}
          </button>
        ))}
      </>
    );
  };

  const renderPosNames = () => {
    try {
      let ans = [];

      for (let each of poData) {
        ans.push(<th key={each["po"]}>{each["po"]}</th>);
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPsosNames = () => {
    try {
      let ans = [];

      for (let each of psoData) {
        ans.push(<th key={each["po"]}>{each["po"]}</th>);
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderStructure = (rowsCount, table) => {
    let result = [];

    let rowsCountUpdated = rowsCount;

    if (statusCode === "303") {
      rowsCountUpdated = table === "1" ? desc.length : desc2.length;
    }

    for (let count = 1; count <= rowsCountUpdated; count++) {
      result.push(
        <tr key={`sem-${semButton}-${table}-${count}`}>
          <td key={`sem-${semButton}-${table}-${count}`}>{`CO${count}`}</td>
          {[...poData, ...psoData].map((each) => (
            <td key={`sem-${semButton}-${table}-${count}-${each["po"]}`}>
              {statusCode === "303" ? (
                <span className="text-[#606981]">
                  {filtArtData?.[table]?.[`CO${count}`]?.[each["po"]]?.[
                    "weightage"
                  ] ?? ""}
                </span>
              ) : (
                <select
                  data-name={
                    filtArtData?.[table]?.[`CO${count}`]?.[each["po"]]?.[
                      "po_level"
                    ] ?? ""
                  }
                  className={`${styles["label-box"]} rounded border-slate-300 focus:ring-0`}
                  name={
                    filtData?.[table]?.[count - 1]?.["course_outcome_id"] ?? ""
                  }
                  data-coartid={
                    filtArtData?.[table]?.[`CO${count}`]?.[each["po"]]?.[
                      "course_art_id"
                    ] ?? ""
                  }
                  id={`${each["po"]}-${table}-${count}-${semButton}`}
                  defaultValue={
                    filtArtData?.[table]?.[`CO${count}`]?.[each["po"]]?.[
                      "po_level"
                    ] ?? ""
                  }
                >
                  <option value="">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              )}
            </td>
          ))}
        </tr>
      );
    }
    return result;
  };

  useEffect(() => {
    getPoData();
  }, []);

  useEffect(() => {
    branch !== undefined && getPsoData();
  }, [branch]);

  useEffect(() => {
    if (statusCode === "303") {
      const semCourses = metricInfo?.["course-ids"]?.filter(
        (each) => `${each["semester"]}` === `${semButton}`
      );

      if (semCourses.length > 0) {
        const ids = semCourses?.[0]?.["course_ids"]?.split(",");

        ids?.forEach((each, idx) => {
          getDescCo(each, `${idx + 1}`);
        });

        setCourseIdList(ids);
      }

      branch !== undefined && academicYear !== undefined && getCourses();

      setIsCourse(semCourses.length > 0);
    } else {
      const filteredData = metricInfo?.["course-outcome-table"]?.filter(
        (each) => `${each["semester"]}` === `${semButton}`
      );

      const groupByTableNum = filteredData?.reduce((acc, item) => {
        const tableNum = item["table_num"];

        if (!acc[tableNum]) {
          acc[tableNum] = [];
        }

        acc[tableNum] = [...acc[tableNum], item];

        return acc;
      }, {});

      const filteredArtData = metricInfo?.[
        "course-articulation-matrix-table"
      ]?.filter((each) => `${each["semester"]}` === `${semButton}`);

      const groupByTableNumArt = filteredArtData?.reduce((acc, item) => {
        const tableNum = item["table_num"];
        const co = item["co_name"];
        const po = item["po_name"];

        if (!acc[tableNum]) {
          acc[tableNum] = {};
        }

        if (!acc[tableNum][co]) {
          acc[tableNum][co] = {};
        }

        if (!acc[tableNum][co][po]) {
          acc[tableNum][co][po] = {};
        }

        acc[tableNum][co][po] = item;

        return acc;
      }, {});

      updateFiltArtData(groupByTableNumArt);

      updateFiltData(groupByTableNum);
    }
  }, [branch, academicYear, statusCode, semButton]);

  useEffect(() => {
    if (statusCode === "303") {
      const groupByTableNumArt = art?.reduce((acc, item) => {
        const co = item["coName"];
        const po = item["poName"] === null ? item["psoName"] : item["poName"];

        if (!acc[co]) {
          acc[co] = {};
        }

        if (!acc[co][po]) {
          acc[co][po] = {};
        }

        acc[co][po] = item;

        return acc;
      }, {});

      const groupByTableNumArt2 = art2?.reduce((acc, item) => {
        const co = item["coName"];
        const po = item["poName"] === null ? item["psoName"] : item["poName"];

        if (!acc[co]) {
          acc[co] = {};
        }

        if (!acc[co][po]) {
          acc[co][po] = {};
        }

        acc[co][po] = item;

        return acc;
      }, {});

      updateFiltArtData({ 1: groupByTableNumArt, 2: groupByTableNumArt2 });
    }
  }, [art, art2]);

  useEffect(() => {
    setRowsCount1(filtData?.["1"]?.length ?? 0);
    setRowsCount2(filtData?.["2"]?.length ?? 0);
  }, [filtData]);

  const scrollTbClickedLeft = () => {
    const container = document.getElementById("scrollTb");
    sideScroll(container, "left", 25, 100, 400);
  };

  const scrollTbClicked = () => {
    const container = document.getElementById("scrollTb");
    sideScroll(container, "right", 25, 100, 400);
  };

  const scrollTbClickedLeft2 = () => {
    const container = document.getElementById("scrollTb2");
    sideScroll(container, "left", 25, 100, 400);
  };

  const scrollTbClicked2 = () => {
    const container = document.getElementById("scrollTb2");
    sideScroll(container, "right", 25, 100, 400);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  const renderEmptyView = (table) => {
    return (
      <div className="flex justify-center items-center p-10 mt-20 mb-5">
        <p
          className={styles["empty-view"]}
        >{`To view or edit this metric table you need to insert data for the ${table}${
          table === "1" ? "st" : "nd"
        } course in semester ${semButton} in Course Outcome Metric`}</p>
      </div>
    );
  };

  return (
    <div className="p-2 pl-1 pr-0 flex flex-col">
      <label className={styles["span-sem"]}>Choose Semester</label>
      <div className="mb-8">{renderButtonOptions()}</div>

      {(statusCode !== "303" || !isCourse) && rowsCount1 === 0 ? (
        renderEmptyView("1")
      ) : (
        <>
          <div className="flex">
            <p className={styles["course-info"]}>
              Course Name:{" "}
              {statusCode === "303" ? (
                <span className="text-[#606981]">
                  {coursesData?.filter(
                    (each) => `${each?.["courseId"]}` === `${courseIdList?.[0]}`
                  )?.[0]?.["courseName"] ?? ""}
                </span>
              ) : (
                <span>{filtData?.["1"]?.[0]?.["course_name"] ?? ""}</span>
              )}
            </p>
            <p className={styles["course-info"]}>
              Course Code:{" "}
              {statusCode === "303" ? (
                <span className="text-[#606981]">
                  {coursesData?.filter(
                    (each) => `${each?.["courseId"]}` === `${courseIdList?.[0]}`
                  )?.[0]?.["courseCode"] ?? ""}
                </span>
              ) : (
                <span>{filtData?.["1"]?.[0]?.["course_code"] ?? ""}</span>
              )}
            </p>
          </div>
          <div className="w-full mt-2">
            <div className={styles.table}>
              <div
                id="scrollTb"
                className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
              >
                <table className={styles["course-art-table"]}>
                  <tr>
                    <th rowSpan={2}>
                      Course Outcomes <br /> (COs)
                    </th>

                    <th colSpan={poData.length}>Program Outcomes (POs)</th>
                    <th colSpan={psoData.length}>
                      Program Specific Outcomes (PSOs)
                    </th>
                  </tr>

                  <tr>
                    {renderPosNames()}
                    {renderPsosNames()}
                  </tr>

                  {renderStructure(rowsCount1, "1")}
                </table>
              </div>
            </div>
          </div>
          <span className="flex self-end mt-4">
            <span
              onClick={scrollTbClickedLeft}
              className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] cursor-pointer rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={scrollTbClicked}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 cursor-pointer pb-1.5 pl-2 pr-2"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>

          {rowsCount1 > 0 && !(statusCode === "303") ? (
            <button
              onClick={submitted1}
              className={styles["save-btn"]}
              type="button"
            >
              Save
            </button>
          ) : (
            <button
              onClick={() => statusChangeBtn({ target: { name: 2 } })}
              className={`${styles["save-btn"]} mt-2 mb-5`}
              type="button"
            >
              Save
            </button>
          )}
        </>
      )}
      {(statusCode !== "303" || !isCourse) && rowsCount2 === 0 ? (
        renderEmptyView("2")
      ) : (
        <>
          <div className="flex mt-20">
            <p className={styles["course-info"]}>
              Course Name:{" "}
              {statusCode === "303" ? (
                <span className="text-[#606981]">
                  {coursesData?.filter(
                    (each) => `${each?.["courseId"]}` === `${courseIdList?.[1]}`
                  )?.[0]?.["courseName"] ?? ""}
                </span>
              ) : (
                <span>{filtData?.["2"]?.[0]?.["course_name"] ?? ""}</span>
              )}
            </p>
            <p className={styles["course-info"]}>
              Course Code:{" "}
              {statusCode === "303" ? (
                <span className="text-[#606981]">
                  {coursesData?.filter(
                    (each) => `${each?.["courseId"]}` === `${courseIdList?.[1]}`
                  )?.[0]?.["courseCode"] ?? ""}
                </span>
              ) : (
                <span>{filtData?.["2"]?.[0]?.["course_code"] ?? ""}</span>
              )}
            </p>
          </div>
          <div className="w-full">
            <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
              <div className={styles.table}>
                <div
                  id="scrollTb2"
                  className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                >
                  <table className={styles["course-art-table"]}>
                    <tr>
                      <th rowSpan={2}>
                        Course Outcomes <br /> (COs)
                      </th>

                      <th colSpan={poData.length}>Program Outcomes (POs)</th>
                      <th colSpan={psoData.length}>
                        Program Specific Outcomes (PSOs)
                      </th>
                    </tr>

                    <tr>
                      {renderPosNames()}
                      {renderPsosNames()}
                    </tr>

                    {renderStructure(rowsCount2, "2")}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <span className="flex self-end mt-4">
            <span
              onClick={scrollTbClickedLeft2}
              className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] cursor-pointer rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={scrollTbClicked2}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 cursor-pointer pb-1.5 pl-2 pr-2"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>

          {rowsCount2 > 0 && !(statusCode === "303") ? (
            <button
              onClick={submitted2}
              className={styles["save-btn"]}
              type="button"
            >
              Save
            </button>
          ) : (
            <button
              onClick={() => statusChangeBtn({ target: { name: 2 } })}
              className={`${styles["save-btn"]} mt-2 mb-2`}
              type="button"
            >
              Save
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default CourseArticulationMatrix;
