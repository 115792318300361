import styles from "./index.module.css";

const RecentActivity = () => {
  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>Coming Soon</p>
    </div>
  );

  return (
    <>
      <div className={styles["activity-container"]}>
        <h1>Recent Activity</h1>
        {true ? (
          renderEmptyView()
        ) : (
          <ul className="list-none">
            <li className="flex items-center mb-5">
              <img src="/assets/timer.svg" alt="activity" />
              <p className={styles["activity-desc"]}>
                <span className={styles["activity-desc-name"]}>Karen</span>{" "}
                leave some comments on Konsep Ilustrasi
                <br />
                <span className={styles["time"]}>20mins ago</span>
              </p>
            </li>
            <li className="flex items-center mb-5">
              <img src="/assets/timer.svg" alt="activity" />
              <p className={styles["activity-desc"]}>
                <span className={styles["activity-desc-name"]}>Karen</span>{" "}
                leave some comments on Konsep Ilustrasi
                <br />
                <span className={styles["time"]}>20mins ago</span>
              </p>
            </li>
            <li className="flex items-center mb-5">
              <img src="/assets/timer.svg" alt="activity" />
              <p className={styles["activity-desc"]}>
                <span className={styles["activity-desc-name"]}>Karen</span>{" "}
                leave some comments on Konsep Ilustrasi
                <br />
                <span className={styles["time"]}>20mins ago</span>
              </p>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default RecentActivity;
