import ManageCourses from "./ManageCourses";
import Attainments from "../Attainments";
import SideBar from "../../layouts/SideBar";
import FacultyNavBar from "../../layouts/FacultyNavBar";
import "./index.css";
import "../../LoginForm/index.css";

const FacultyDashboardPage = (props) => {
  const { match } = props;
  const { path } = match;
  return (
    <span className="flex flex-row justify-center h-screen overflow-hidden">
      <div className="home flex flex-row overflow-hidden bg-[#FFFFFF]">
        <FacultyNavBar />
        <div className="flex justify-between w-[100%] h-screen">
          <span className="w-[12%]">
            <SideBar />
          </span>
          <div className="bg-[#fff] w-[88%] relative h-screen overflow-y-auto">
            {path.includes("/faculty/dashboard/managecourses") ? (
              <div className="flex flex-col overflow-hidden">
                <ManageCourses />
              </div>
            ) : (
              <div className="flex flex-col overflow-y-auto">
                <Attainments />
              </div>
            )}
          </div>
        </div>
      </div>
    </span>
  );
};

export default FacultyDashboardPage;
