import { Link, withRouter } from "react-router-dom";
import styles from "./index.module.css";
import { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import SearchContext from "../../../context/SearchContext";

const FacultyNavBar = (props) => {
  const { match, history } = props;
  const { path } = match;

  const [popActive, updatePop] = useState(false);
  const { search, updateSearch } = useContext(SearchContext);

  const popBtn = () => {
    updatePop(!popActive);
  };

  const searchChanged = (event) => {
    updateSearch(event.target.value);
  };

  useEffect(() => {
    return () => {
      updateSearch("");
    };
  }, [path]);

  const logoutClicked = () => {
    const { history } = props;
    Cookies.remove("jwt_token");
    history.replace("/login");
  };

  document.addEventListener("click", (e) => {
    if (e.target.id === "box") {
      if (popActive === false) {
        updatePop(true);
      }
    } else {
      updatePop(false);
    }
  });

  return (
    <nav
      className={`${styles["nav-bar"]} flex flex-row justify-start items-center`}
    >
      <div
        className={`${styles["rubrix-logo-container"]} flex flex-row justify-between items-center`}
      >
        <img
          src="/assets/logo.svg"
          alt="Fatcat"
          className={styles["rubrix-icon"]}
        />
        <img
          src="/assets/fatcatTitle.svg"
          alt="Fatcat"
          className={styles["fatcat-title"]}
        />
      </div>

      <div className="flex justify-between items-center w-[89%]">
        <span className="flex flex-row items-center w-[60%]">
          <ul
            className={`${styles["tab-bar"]} flex flex-row justify-around items-center bg-[#ECF0FF] list-style-none`}
          >
            <li
              className={`hover:text-[#3f4451] ${
                path.startsWith("/faculty/dashboard")
                  ? "text-[#3D65F4] font-black"
                  : "text-[#606981"
              }`}
            >
              <Link to="/faculty/dashboard/managecourses">Dashboard</Link>
            </li>
            <li
              className={`hover:text-[#3f4451] ${
                path.startsWith("/faculty/learn")
                  ? "text-[#3D65F4] font-black"
                  : "text-[#606981"
              }`}
            >
              <Link to="/faculty/learn/mycourses">Learn</Link>
            </li>
          </ul>

          <div
            className={`${styles["arrows-container"]} flex flex-row justify-between items-center ml-10`}
          >
            <div
              onClick={() => history.goBack()}
              className={`${styles["arrow-bg"]} cursor-pointer`}
            >
              <img
                src="/assets/leftarrow.svg"
                width={9}
                height={9}
                alt="leftarrow"
              />
            </div>
            <div
              onClick={() => history.goForward()}
              className={`${styles["arrow-bg"]} cursor-pointer`}
            >
              <img
                src="/assets/rightarrow.svg"
                width={9}
                height={9}
                alt="rightarrow"
              />
            </div>
          </div>
        </span>

        <span className="flex flex-row justify-between items-center w-[40%]">
          {path === "/faculty/dashboard/managecourses" ? (
            <form className={`${styles["input-el-container"]}`}>
              <img
                src="/assets/search.svg"
                className="mr-2"
                width={15}
                height={15}
                alt="search"
              />
              <input
                value={search}
                onChange={searchChanged}
                placeholder="Search for courses"
                className={`${styles["input-el"]} mr-4 w-full h-full focus:ring-0`}
                type="search"
              />
            </form>
          ) : (
            <span className="w-[65%]"></span>
          )}

          <span className="flex items-center">
            <div className="ml-2">
              <span className="cursor-not-allowed text-gray-500 mr-8 flex items-center">
                <span className={`${styles.bell} flex flex-col items-center`}>
                  <img
                    src="/assets/notification.svg"
                    width={20}
                    height={20}
                    alt="notifications"
                  />
                  <img
                    src="/assets/bellbottom.svg"
                    width={8}
                    height={8}
                    alt="notifications"
                  />
                </span>
                <span
                  className={`${styles.count} flex justify-center items-center text-white bg-orange-700 absolute border-2 border-white mb-2 ml-4 px-1`}
                >
                  0
                </span>
              </span>
            </div>

            <div
              className={`${styles.profile} ml-5 cursor-pointer`}
              onClick={popBtn}
            >
              <img
                id="box"
                src="/assets/defaultImage.svg"
                className={`rounded-full`}
                width={45}
                height={45}
                alt="profile"
              />
              {popActive && (
                <span className={styles["drop-down"]}>
                  <ul className="flex flex-col p-5 pb-0 list-none">
                    <Link to="/faculty/account">
                      <li className={styles["li-items"]}>
                        <img
                          src="/assets/myAccount.svg"
                          width={18}
                          height={18}
                          alt="Account"
                        />
                        <span className={styles["li-text"]}>My Account</span>
                      </li>
                    </Link>
                  </ul>
                  <button
                    onClick={logoutClicked}
                    className={`${styles["logout-btn"]} ml-6`}
                  >
                    Logout
                  </button>
                </span>
              )}
            </div>
          </span>
        </span>
      </div>
    </nav>
  );
};

export default withRouter(FacultyNavBar);
