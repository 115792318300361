import styles from "./index.module.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const AttainmentsOverallTable = (props) => {
  const { uploadedStatus, branch, metricInfo, metricsId, template } = props;

  const [poData, updatePoData] = useState([]);
  const [psoData, updatePsoData] = useState([]);
  const [filtOverallData, setFiltOverallData] = useState({});

  const sideScroll = (element, direction, speed, distance, step) => {
    if (!element) {
      console.error("Element not found.");
      return;
    }

    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  const artscrollTbClickedLeft = (each) => {
    const container = document.getElementById(each);
    sideScroll(container, "left", 25, 100, 400);
  };

  const artscrollTbClicked = (each) => {
    const container = document.getElementById(each);
    sideScroll(container, "right", 25, 100, 400);
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);
      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${branch}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updatePsoData(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderPosNames = () => {
    try {
      let ans = [];
      for (let each of [...poData, ...psoData]) {
        ans.push(<th key={each["po"]}>{each["po"]}</th>);
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderOverallTable = (row) => {
    return [...poData, ...psoData].map((each) => (
      <td>
        <input
          defaultValue={
            filtOverallData?.[row]?.[each?.["po"]]?.[0]?.["po_att_value"] ?? ""
          }
          name={
            filtOverallData?.[row]?.[each?.["po"]]?.[0]?.[
              "po_pso_overall_att_id"
            ] ?? ""
          }
          id={`${row}-${each?.["po"]}`}
          className={styles["label-box"]}
        />
      </td>
    ));
  };

  const overAllTableSubmit = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count of ["DA", "IDA", "OVERALL"]) {
        const overAllBody = [...poData, ...psoData].map((each) => ({
          poPsoOverallAttId: document.getElementById(`${count}-${each?.["po"]}`)
            .name,
          assessmentName: count,
          poName: each?.["po"],
          poValue: document.getElementById(`${count}-${each?.["po"]}`).value,
        }));

        reqBody = [...reqBody, ...overAllBody];
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const formatData = () => {
    try {
      const groupByAssessment = Object.groupBy(
        metricInfo?.[template],
        ({ assessment_name }) => assessment_name
      );

      const resultAssessment = Object.fromEntries(
        Object.entries(groupByAssessment).map(([assessment_name, group]) => {
          const groupedByPo = Object.groupBy(group, ({ po_name }) => po_name);
          return [assessment_name, groupedByPo];
        })
      );

      setFiltOverallData(resultAssessment);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPoData();
    formatData();
  }, []);

  useEffect(() => {
    branch !== undefined && getPsoData();
  }, [branch]);

  return (
    <div className="pl-2">
      <form onSubmit={overAllTableSubmit} className="flex flex-col">
        <div className="w-full">
          <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
            <div className={styles.table}>
              <div
                id="overAllTable"
                className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
              >
                <table className={styles["attainments-table"]}>
                  <thead>
                    <tr>
                      <th className={styles["course-info"]}>Assessment</th>

                      {renderPosNames()}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Direct Attainment</td>

                      {renderOverallTable("DA")}
                    </tr>
                    <tr>
                      <td>Indirect Attainment</td>
                      {renderOverallTable("IDA")}
                    </tr>
                    <tr>
                      <td>Overall Attainment</td>
                      {renderOverallTable("OVERALL")}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <span className="flex justify-end self-end mt-3 mb-5">
          <span
            onClick={() => artscrollTbClickedLeft("overAllTable")}
            className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
          <span
            onClick={() => artscrollTbClicked("overAllTable")}
            className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
        </span>

        <button className={`${styles["save-btn"]} mt-2 mb-5`} type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default AttainmentsOverallTable;
