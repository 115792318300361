import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import apiClient from "../../../utils/apiUrls/apiClient";
import { MdDeleteOutline } from "react-icons/md";
import SideBar from "../../layouts/SideBar";
import CampusNavBar from "../../layouts/CampusNavBar";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import "../../LoginForm/index.css";
import NavLink from "../NavLink";
import Popup from "reactjs-popup";

const ManageCampusSetup = (props) => {
  const { match } = props;
  const { path } = match;
  const [claims, updateClaims] = useState({});

  const [getPsoLoader, updateGetPsoLoader] = useState(true);

  const [popUp, updatePop] = useState(false);
  const [deletedPsoNum, updateDeletedPsoNum] = useState(null);

  const [regLoading, updateRegLoading] = useState(false);
  const [batchLoading, updateBatchLoading] = useState(false);
  const [deptLoading, updateDeptLoading] = useState(false);
  const [psoLoading, updatePsoLoading] = useState(false);
  const [sectionLoading, updateSectionLoading] = useState(false);
  const [dropBatchOptions, UpdateBatchOptions] = useState([]);

  const [psoBranch, updatePsoBranch] = useState("");

  const [regulations, updateRegulations] = useState([]);
  const [dept, updateDept] = useState([]);
  const [pso, updatePso] = useState([]);
  const [piCodes, updatePiCode] = useState([]);

  const [sectionData, updateSecData] = useState([]);

  const [regValue, updateRegValue] = useState("");
  const [batchValue, updateBatchValue] = useState({
    batchName: "",
    regulationName: "",
  });

  const [deptValue, updateDeptValue] = useState({
    deptName: "",
    deptLongName: "",
    piDeptCode: "",
  });

  const [sectionValue, updateSectionValue] = useState({
    deptName: "",
    batchId: "",
    noOfSections: "",
    sectionList: "",
  });

  const [psoValue, updatePsoValue] = useState({
    psoDesc: "",
    psoNo: "",
  });

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBatchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const psoNoChanged = (event) => {
    updatePsoValue({ ...psoValue, psoNo: event.target.value });
  };

  const psoDescChanged = (event) => {
    updatePsoValue({ ...psoValue, psoDesc: event.target.value });
  };

  const regValueChanged = (event) => {
    updateRegValue(event.target.value);
  };

  const batchValueRegChanged = (event) => {
    updateBatchValue({ ...batchValue, regulationName: event.target.value });
  };

  const batchValueBatchChanged = (event) => {
    updateBatchValue({ ...batchValue, batchName: event.target.value });
  };

  const deptValueShortChanged = (event) => {
    updateDeptValue({ ...deptValue, deptName: event.target.value });
  };

  const deptValueLongChanged = (event) => {
    updateDeptValue({ ...deptValue, deptLongName: event.target.value });
  };

  const deptValueCodeChanged = (event) => {
    updateDeptValue({ ...deptValue, piDeptCode: event.target.value });
  };

  const sectionsDeptChanged = (event) => {
    updateSectionValue({
      ...sectionValue,
      deptName: event.target.value,
    });
  };

  const sectionsBatchChanged = (event) => {
    updateSectionValue({
      ...sectionValue,
      batchId: event.target.value,
    });
  };

  const sectionsChanged = (event) => {
    try {
      const sec = parseInt(event.target.value);
      if (sec > 0) {
        let list = [];

        for (let i = 0; i < sec; i++) {
          list.push(String.fromCharCode(65 + i));
        }

        const updated_list = list.join(",");

        updateSectionValue({
          ...sectionValue,
          noOfSections: sec,
          sectionList: updated_list,
        });
      } else {
        updateSectionValue({
          ...sectionValue,
          noOfSections: sec,
          sectionList: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const psoBranchChanged = (event) => {
    updatePsoBranch(event.target.value);
  };

  const getRegData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.CAMPUS_SETUP_REG_BATCH_INFO,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateRegulations(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDeptCodeOptions = async () => {
    const token = Cookies.get("jwt_token");

    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=PI_DEPT_CODE&screenName=OBE_DEPT_PI`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updatePiCode(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDeptData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.CAMPUS_SETUP_DEPT_INFO,
        { Authorization: token }
      );

      let claim = null;

      claim = jose.decodeJwt(token);
      // console.log(response['data']['result']);
      if (response["data"]["statusCode"] === "OK") {
        updateDept(response["data"]["result"]);
        if (
          response["data"]["result"].length > 0 &&
          claim["user_type"] === "PRINCIPAL"
        ) {
          updatePsoBranch(response["data"]["result"][0]["deptName"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSectionsData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      let batch = dropBatchOptions.filter(
        (each) => each.optionValue === sectionValue["batchId"]
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?batch=${batch[0]["optionDesc"]}&branch=${sectionValue["deptName"]}`,
        {
          Authorization: token,
        }
      );
      if (response["data"]["statusCode"] === "OK") {
        updateSecData(response["data"]["result"]);
        if (response["data"]["result"].length > 0) {
          updateSectionValue({
            ...sectionValue,
            noOfSections: response["data"]["result"].length,
          });
        } else {
          updateSecData([]);
          updateSectionValue({
            ...sectionValue,
            noOfSections: "",
          });
        }
      } else {
        updateSecData([]);
        updateSectionValue({
          ...sectionValue,
          noOfSections: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderExistingSections = () => {
    const ans = [];

    try {
      // let batch = dropBatchOptions.filter(
      //   (each) => each.optionValue === sectionValue["batchId"]
      // );

      sectionData.map((each) =>
        ans.push(
          <li className={styles["department-btn"]}>
            {/* <span>Batch: {batch[0]["optionDesc"]}</span> */}
            {/* {sectionValue["deptName"]} */}
            {each}
          </li>
        )
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getPsoData = async () => {
    updateGetPsoLoader(true);
    updateDeletedPsoNum(null);

    const token = Cookies.get("jwt_token");
    try {
      if (psoBranch !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${psoBranch}`,
          { Authorization: token }
        );
        // console.log(response['data']['result']);
        if (response["data"]["statusCode"] === "OK") {
          updatePso(response["data"]["result"]);
        } else {
          updatePso([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
    updateGetPsoLoader(false);
  };

  const getRegs = () => {
    try {
      const ans = Object.keys(regulations)
        .sort()
        .map((each) => (
          <li key={each} className={styles["regulation-btn"]}>
            {each}
          </li>
        ));

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getRegOptions = () => {
    try {
      const ans = Object.keys(regulations)
        .sort()
        .map((each) => (
          <option key={each} value={each}>
            {each}
          </option>
        ));

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderDeptCodeOptions = () => {
    try {
      const ans = piCodes.map((each) => (
        <option key={each["optionValue"]} value={each["optionValue"]}>
          {each["optionDesc"]}
        </option>
      ));

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getBatches = (reg) => {
    const ans = [];

    try {
      ans.push(<p className={styles["reg-name"]}>{reg}</p>);

      for (const each of regulations[reg]) {
        ans.push(<p className={styles["reg-batch"]}>{each}</p>);
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const openModal = (poNum) => {
    updateDeletedPsoNum(poNum);
    updatePop(true);
  };

  const closeModal = async () => {
    updateDeletedPsoNum(null);
    updatePop(false);
  };

  const overlayStyle = { background: "rgba(0,0,0,0.2)" };

  const renderPopup = () => {
    return (
      <div className={`${styles["modal"]}`}>
        <p className={`${styles.text} text-center mb-10`}>
          Do you want to delete the{" "}
          <span className="capitalize font-medium">{deletedPsoNum}</span>?
        </p>

        <span>
          <button
            onClick={closeModal}
            className="mr-9 text-blue-600 font-medium bg-[#F4F9FF] rounded px-5 py-1 border"
          >
            No
          </button>
          <button
            onClick={deletePso}
            className="text-blue-600 font-medium bg-red-600 text-white rounded px-5 py-1 border"
          >
            Yes
          </button>
        </span>
      </div>
    );
  };

  const renderPso = () => {
    const ans = [];

    try {
      for (const item of pso) {
        ans.push(
          <div className={styles["existing-pso-container"]}>
            <div
              className={`${styles["existing-pso-box"]} text-ellipsis overflow-hidden`}
            >
              <p className={styles["pso-btn"]}>{item["po"]}</p>
              <p className="text-ellipsis overflow-hidden">{item["poDesc"]}</p>
            </div>
            <span className="hidden md:flex flex-row flex flex-row items-center ml-10">
              <Popup
                open={popUp}
                onClose={closeModal}
                closeOnDocumentClick={false}
                {...{ overlayStyle }}
              >
                {renderPopup()}
              </Popup>
              <MdDeleteOutline
                key={item["po"]}
                onClick={() => openModal(item["po"])}
                className="text-red-600 ml-5 cursor-pointer"
                size={20}
              />
            </span>
          </div>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getDept = () => {
    const ans = [];
    try {
      dept.map((each) =>
        ans.push(
          <li className={styles["department-btn"]}>
            {each["deptName"]} {each["deptNickName"] !== "" && "-"}{" "}
            {each["deptNickName"]}
          </li>
        )
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      await getRegData();
      await getDeptData();
      await getBatchOptions();
      await getDeptCodeOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const storeReg = async (event) => {
    event.preventDefault();
    updateRegLoading(true);
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.CAMPUS_SETUP_STORE_REG_INFO +
          `?regulationName=${regValue}`,
        options
      );

      if (response["ok"] === true) {
        getRegData();
        updateRegValue("");
      } else {
        alert("Regulation Not Inserted");
      }
      updateRegLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const storeBatch = async (event) => {
    event.preventDefault();
    updateBatchLoading(true);

    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(batchValue),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CAMPUS_SETUP_STORE_BATCH_INFO,
        options
      );

      if (response["ok"] === true) {
        setTimeout(() => {
          getRegData();
          getBatchOptions();
        }, 1000);
        updateBatchValue({
          batchName: "",
          regulationName: "",
        });
      } else {
        alert("Batch Not Inserted");
      }
      updateBatchLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const storeDept = async (event) => {
    event.preventDefault();
    updateDeptLoading(true);
    const token = Cookies.get("jwt_token");
    try {
      if (deptValue.deptName.includes("&")) {
        alert("'&' symbol is not allowed. Please remove and try again");
      } else {
        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(deptValue),
        };

        const response = await fetch(
          apiClient.urls.fatcat.CAMPUS_SETUP_STORE_DEPT_INFO,
          options
        );

        if (response["ok"] === true) {
          getDeptData();
          getRegData();
          updateDeptValue({
            deptName: "",
            deptLongName: "",
            piDeptCode: "",
          });
        } else {
          alert("Department Not Inserted");
        }
      }
      updateDeptLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const storeSections = async (event) => {
    event.preventDefault();
    updateSectionLoading(true);
    const token = Cookies.get("jwt_token");
    try {
      if (
        sectionValue.noOfSections > 0 &&
        sectionValue.sectionList.length > 0
      ) {
        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(sectionValue),
        };

        const response = await fetch(
          apiClient.urls.fatcat.STORE_SECTIONS,
          options
        );

        if (response["ok"] === true) {
          getSectionsData();
          updateSectionValue({
            noOfSections: "",
            sectionList: "",
          });
        } else {
          alert("Sections Not Inserted");
        }
      } else {
        alert("No. of sections must be at least 1");
      }
      updateSectionLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const storePso = async (event) => {
    event.preventDefault();
    updatePsoLoading(true);
    const token = Cookies.get("jwt_token");
    try {
      const reqBody = { ...psoValue, branch: psoBranch };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(reqBody),
      };
      if (psoBranch !== "") {
        const response = await fetch(
          apiClient.urls.fatcat.MANAGE_CAMPUS_STORE_PSO,
          options
        );

        if (response["ok"] === true) {
          const res = await response.json();
          if (res["result"] === "OK") {
            getPsoData();
            alert(res["description"]);
            updatePsoValue({
              psoDesc: "",
              psoNo: "",
            });
          } else {
            alert(res["description"]);
          }
        } else {
          alert("Pso Not Inserted");
        }
      } else {
        alert("Select the Branch!");
      }
      updatePsoLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deletePso = async () => {
    updatePsoLoading(true);
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (psoBranch !== "") {
        const response = await fetch(
          apiClient.urls.fatcat.MANAGE_CAMPUS_DELETE_PSO +
            `?branch=${psoBranch}&pso=${deletedPsoNum}`,
          options
        );

        if (response["ok"] === true) {
          const res = await response.json();
          if (res["status"] === "OK") {
            getPsoData();
            alert(res["description"]);
          } else {
            alert(res["description"]);
          }
        } else {
          alert("Pso Not Deleted");
        }
      } else {
        alert("Select the Branch!");
      }
      updatePsoLoading(false);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dept) {
        ans.push(
          <option key={item["deptName"]} value={item["deptName"]}>
            {item["deptName"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSectionBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      const { history } = props;

      token === undefined && history.replace("/login");

      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateClaims(claim);

        if (claim["user_type"] !== "PRINCIPAL") {
          updatePsoBranch(claim["dept_name"]);
        }
        getData();
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      const { history } = props;

      token === undefined && history.replace("/login");
      if (Cookies.get("jwt_token") !== undefined) {
        getSectionsData();
      }
    }
  }, [sectionValue.batchId, sectionValue.deptName]);

  useEffect(() => {
    if (navigator.onLine) {
      getPsoData();
    }
  }, [psoBranch]);

  return (
    <span className="flex flex-row justify-center h-screen overflow-hidden">
      <div
        className={`${styles["home"]} flex flex-row overflow-hidden bg-[#FFFFFF]`}
      >
        <CampusNavBar />
        <div className="flex justify-between w-[100%] h-screen">
          <span className="w-[12%]">
            <SideBar />
          </span>
          <div className="bg-[#fff] w-[88%] relative h-screen overflow-hidden overflow-y-auto">
            <NavLink path={path} claims={claims} />

            <div className={styles["account-container"]}>
              {!(claims["user_type"] === "HOD") && (
                <>
                  <form
                    onSubmit={storeReg}
                    className={`${styles["info-container"]} flex flex-col`}
                  >
                    <p className={styles["regulation-main-heading"]}>
                      Enter Regulation Info
                    </p>
                    <span className="flex items-end mb-5">
                      <span className="mr-10">
                        <p className={styles["label"]}>Regulation Name</p>
                        <input
                          required
                          onChange={regValueChanged}
                          value={regValue}
                          type="text"
                          className={`${styles["info-input"]} p-2`}
                          placeholder="Ex : R-18"
                        />
                      </span>
                      <span>
                        <button
                          disabled={regLoading}
                          type="submit"
                          className={`${styles["insert-btn"]} ${
                            regLoading &&
                            "cursor-not-allowed flex justify-center items-center"
                          }`}
                        >
                          {regLoading ? (
                            <Oval
                              height={20}
                              width={20}
                              color="#FFFFFF"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#FFF"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          ) : (
                            "Insert Regulation"
                          )}
                        </button>
                      </span>
                    </span>

                    <p className={`${styles["label"]} mt-6 mb-3`}>
                      Existing Regulations:
                    </p>
                    <span className="flex items-center">
                      <ul className="flex flex-wrap">{getRegs()}</ul>
                    </span>
                  </form>

                  <div
                    className={`${styles["info-container"]} flex flex-col mt-5`}
                  >
                    <p className={styles["regulation-main-heading"]}>
                      Enter Batch Info
                    </p>
                    <form onSubmit={storeBatch} className="flex items-end mb-5">
                      <span className="flex items-center mr-10">
                        <span className="mr-10">
                          <p className={styles["label"]}>Select Regulation</p>
                          <select
                            onChange={batchValueRegChanged}
                            required
                            value={batchValue["regulationName"]}
                            className={`${styles["filter-txt"]} p-2`}
                          >
                            <option selected disabled hidden value="">
                              SELECT
                            </option>
                            {getRegOptions()}
                          </select>
                        </span>

                        <span>
                          <p className={styles["label"]}>Enter Batch</p>
                          <input
                            onChange={batchValueBatchChanged}
                            required
                            value={batchValue["batchName"]}
                            type="text"
                            className={`${styles["info-input"]} p-2`}
                            placeholder="Ex : 2018-2022"
                          />
                        </span>
                      </span>
                      <span>
                        <button
                          disabled={batchLoading}
                          type="submit"
                          className={`${styles["insert-batch-btn"]} ${
                            batchLoading &&
                            "cursor-not-allowed flex justify-center items-center"
                          }`}
                        >
                          {batchLoading ? (
                            <Oval
                              height={20}
                              width={20}
                              color="#FFFFFF"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#FFF"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          ) : (
                            "Insert Batch"
                          )}
                        </button>
                      </span>
                    </form>

                    <p className={`${styles["label"]} mt-6 mb-4`}>
                      Existing Batch Info
                    </p>
                    <div className="flex flex-wrap">
                      {regulations.length !== 0 &&
                        Object.keys(regulations)
                          .sort()
                          .map((each) => {
                            return (
                              <span
                                key={each}
                                className="flex flex-col mr-14 mb-5"
                              >
                                {getBatches(each)}
                              </span>
                            );
                          })}
                    </div>
                  </div>

                  <div
                    className={`${styles["info-container"]} flex flex-col mt-5`}
                  >
                    <p className={styles["regulation-main-heading"]}>
                      Enter Department Info
                    </p>
                    <form onSubmit={storeDept} className="flex flex-col">
                      <span className="flex items-end mb-5">
                        <span className="flex items-center mr-10">
                          <span className="mr-5">
                            <p className={styles["label"]}>
                              Enter Short Name Of Department
                            </p>
                            <input
                              required
                              onChange={deptValueShortChanged}
                              value={deptValue["deptName"]}
                              type="text"
                              className={`${styles["dept-info-input"]} p-2`}
                              placeholder="IT"
                            />
                          </span>

                          <span className="mr-5">
                            <p className={styles["label"]}>
                              Enter Full Name Of Department
                            </p>
                            <input
                              required
                              onChange={deptValueLongChanged}
                              value={deptValue["deptLongName"]}
                              type="text"
                              className={`${styles["dept-info-input"]} p-2`}
                              placeholder="Information Technology"
                            />
                          </span>

                          <span className="mr-4">
                            <p className={styles["label"]}>
                              Select PI Department Code
                            </p>
                            <select
                              onChange={deptValueCodeChanged}
                              required
                              value={deptValue["piDeptCode"]}
                              className={`${styles["dept-filter-txt"]} p-2`}
                            >
                              <option selected disabled hidden value="">
                                SELECT
                              </option>
                              {renderDeptCodeOptions()}
                            </select>
                          </span>
                        </span>

                        <span>
                          <button
                            disabled={deptLoading}
                            type="submit"
                            className={`${styles["insert-btn"]} ${
                              deptLoading &&
                              "cursor-not-allowed flex justify-center items-center"
                            }`}
                          >
                            {deptLoading ? (
                              <Oval
                                height={20}
                                width={20}
                                color="#FFFFFF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#FFF"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            ) : (
                              "Insert Department"
                            )}
                          </button>
                        </span>
                      </span>
                    </form>

                    <p className={`${styles["label"]} mt-6 mb-3`}>
                      Existing Department Info
                    </p>

                    <ul className="flex flex-wrap items-center">{getDept()}</ul>
                  </div>

                  <div
                    className={`${styles["info-container"]} flex flex-col mt-5`}
                  >
                    <p className={styles["regulation-main-heading"]}>
                      Enter Sections Info
                    </p>
                    <form onSubmit={storeSections} className="flex flex-col">
                      <span className="flex items-end mb-5">
                        <span className="flex items-center mr-10">
                          <span className="mr-8">
                            <p className={styles["label"]}>Select Batch</p>
                            <select
                              onChange={sectionsBatchChanged}
                              required
                              value={sectionValue["batchId"]}
                              className={`${styles["dept-filter-txt"]} p-2`}
                            >
                              <option selected disabled hidden value="">
                                SELECT
                              </option>
                              {renderSectionBatchOptions()}
                            </select>
                          </span>

                          <span className="mr-8">
                            <p className={styles["label"]}>Select Department</p>
                            <select
                              onChange={sectionsDeptChanged}
                              required
                              value={sectionValue["deptName"]}
                              className={`${styles["dept-filter-txt"]} p-2`}
                            >
                              <option selected disabled hidden value="">
                                SELECT
                              </option>
                              {renderBranchOptions()}
                            </select>
                          </span>

                          <span className="mr-8">
                            <p className={styles["label"]}>No. of sections</p>
                            <input
                              onChange={sectionsChanged}
                              required
                              value={sectionValue["noOfSections"]}
                              type="number"
                              className={`${styles["info-input"]} ${styles["section-input"]} p-2`}
                              placeholder="Ex : 1"
                            />
                          </span>
                        </span>

                        <span>
                          <button
                            disabled={sectionLoading}
                            type="submit"
                            className={`${styles["insert-btn"]} ${
                              sectionLoading &&
                              "cursor-not-allowed flex justify-center items-center"
                            }`}
                          >
                            {sectionLoading ? (
                              <Oval
                                height={20}
                                width={20}
                                color="#FFFFFF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#FFF"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            ) : (
                              "Insert Sections"
                            )}
                          </button>
                        </span>
                      </span>
                    </form>

                    <p className={`${styles["label"]} mt-6 mb-3`}>
                      Existing Sections Info
                    </p>

                    <ul className="flex flex-wrap items-center">
                      {renderExistingSections()}
                    </ul>
                  </div>
                </>
              )}

              <div className={`${styles["info-container"]} flex flex-col mt-5`}>
                {!(claims["user_type"] === "HOD") && (
                  <>
                    <p className={styles["label"]}>Select Branch</p>

                    <div
                      className={`${styles["filter-po-container"]} focus:ring-0 mt-3 mr-5 p-2 pr-5`}
                    >
                      <select
                        required
                        onChange={psoBranchChanged}
                        value={psoBranch}
                        className={`${styles["filter-select-po"]} focus:ring-0 p-2`}
                      >
                        {renderBranchOptions()}
                      </select>
                    </div>
                  </>
                )}
                <p className={styles["regulation-main-heading"]}>
                  Program Specific Objectives
                </p>
                <form onSubmit={storePso} className="flex flex-col mb-5">
                  <span className="mr-10">
                    <p className={styles["label"]}>PSO Number</p>
                    <input
                      required
                      onChange={psoNoChanged}
                      value={psoValue["psoNo"]}
                      type="text"
                      className={`${styles["info-input"]} p-2`}
                      placeholder="PSO1"
                    />
                  </span>
                  <span className="mt-8 mb-4">
                    <p className={styles["label"]}>PSO Full Description</p>
                    <textarea
                      rows={5}
                      cols={120}
                      required
                      onChange={psoDescChanged}
                      value={psoValue["psoDesc"]}
                      className={`${styles["info-input-text-area"]} p-2`}
                      placeholder="PSO Description"
                    ></textarea>
                  </span>
                  <span className="self-end">
                    <button
                      disabled={psoLoading}
                      type="submit"
                      className={`${styles["insert-btn"]} ${
                        psoLoading &&
                        "cursor-not-allowed flex justify-center items-center"
                      }`}
                    >
                      {psoLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="#FFFFFF"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#FFF"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      ) : (
                        "Insert PSO"
                      )}
                    </button>
                  </span>
                </form>
                <p className={`${styles["label"]} mt-6 mb-3`}>
                  Existing PSO Info
                </p>
                {getPsoLoader ? (
                  <div className="flex justify-center mt-10">
                    <Oval
                      height={50}
                      width={50}
                      color="#3d65f4"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#3d65f4"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : (
                  renderPso()
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default ManageCampusSetup;
