import { Link, withRouter } from "react-router-dom";
import styles from "./index.module.css";
import { Component } from "react";

class MyCourses extends Component {
  state = {};

  render() {
    const { match } = this.props;
    const { path } = match;

    return (
      <>
        <div className="flex flex-row mt-20 ml-4">
          <Link to="/faculty/learn/mycourses">
            <h1
              className={`${
                path === "/faculty/learn/mycourses"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-4 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
            >
              MY COURSES
            </h1>
          </Link>
          <h1
            className={`${
              path === "/faculty/learn/analysis"
                ? "font-bold underline"
                : "font-medium"
            } ml-6 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-not-allowed`}
          >
            ANALYSIS
          </h1>
        </div>

        <div className={styles["dashboard-periodwisebar-bg"]}>
          <div className={styles["learn-img"]}></div>
        </div>
      </>
    );
  }
}

export default withRouter(MyCourses);
