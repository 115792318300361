const StudentsList = (props) => {
  const { item, studentProfileClick } = props;

  const studentProfile = () => {
    studentProfileClick(item.student_id);
  };

  return (
    <tr className="text-[#606981]" onClick={studentProfile}>
      <td>{item.roll_num}</td>
      <td>
        {item.first_name} {item.last_name}
      </td>

      <td>{item.branch}</td>
      <td className="relative overflow-y-hidden">
        {item.phone_number}
        <img
          className="hidden xl:flex bg-[#E2E9FF] p-2 rounded-2xl absolute right-0 top-2.5"
          src="/assets/contact.svg"
          alt="contact"
        />
      </td>

      <td className={`text-${item.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"}`}>
        {item.total_cos_attained}/{item.total_cos}
      </td>
      <td className={`text-${item.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"}`}>
        {item.avg_att * 1}%
      </td>
    </tr>
  );
};

export default StudentsList;
