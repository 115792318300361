import Cookies from "js-cookie";
import { Component } from "react";
import styles from "./index.module.css";
import CryptoJS from "crypto-js";
import apiClient from "../../../utils/apiUrls/apiClient";
import FacultyList from "../FacultyList";
import SideBar from "../../layouts/SideBar";
import CampusNavBar from "../../layouts/CampusNavBar";
import InitialSetup from "../../InitialSetup";
import { Oval } from "react-loader-spinner";
import * as jose from "jose";
import "../../LoginForm/index.css";
import NavLink from "../NavLink";

const dummyFac = {
  deptName: "",
  designation: "",
  facultyName: "",
  facultyType: "",
  facultyUsername: "",
  loginPassword: "",
  workEmail: "",
};

class ManageHods extends Component {
  state = {
    isLoading: false,
    facData: dummyFac,
    hodsList: [],
    hodCreated: false,
    setUp: "loading",
    branchOptions: [],
    hodDataLoading: true,
    claims: {},
  };

  deptChanged = (event) => {
    const { facData } = this.state;

    this.setState({ facData: { ...facData, deptName: event.target.value } });
  };

  facDel = () => {
    this.getData();
  };

  facultyNameChanged = (event) => {
    const { facData } = this.state;

    this.setState({ facData: { ...facData, facultyName: event.target.value } });
  };

  facultyUserNameChanged = (event) => {
    const { facData } = this.state;

    this.setState({
      facData: { ...facData, facultyUsername: event.target.value },
    });
  };

  pwdChanged = (event) => {
    const { facData } = this.state;

    this.setState({
      facData: { ...facData, loginPassword: event.target.value },
    });
  };

  mailChanged = (event) => {
    const { facData } = this.state;

    this.setState({ facData: { ...facData, workEmail: event.target.value } });
  };

  checkUsername = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { facData } = this.state;
      const response = await apiClient.get(
        apiClient.urls.fatcat.CHECK_USERNAME +
          `?username=${facData["facultyUsername"]}`,
        {
          Authorization: token,
        }
      );

      if (response["data"]["statusCode"] === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  addHod = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      const { facData } = this.state;

      const res = await this.checkUsername();

      const token = Cookies.get("jwt_token");

      const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;
      const encrypted = CryptoJS.AES.encrypt(
        facData["loginPassword"],
        key
      ).toString();

      const type = document.getElementById("freshman");

      const facultyData = {
        ...facData,
        loginPassword: encrypted,
        facultyType: type.checked ? "FRESHMAN" : "HOD",
      };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(facultyData),
      };
      if (res) {
        const response = await fetch(
          apiClient.urls.fatcat.OBE_FACULTY_CREATE_FACULTY,
          options
        );

        // console.log(response);

        if (response["ok"] === true) {
          this.setState({ hodCreated: true, facData: dummyFac });
        } else {
          alert("HoD Not Created");
        }
      } else {
        alert("Username Not Available");
      }
      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  getData = async () => {
    this.setState({ hodDataLoading: true });
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_DETAILS +
          `?branch=&facultyType=HOD&semester=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ hodsList: response["data"]["result"] });
      } else {
        this.setState({ hodsList: [] });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ hodDataLoading: false });
  };

  okBtn = () => {
    this.setState({ hodCreated: false });
    this.getData();
  };

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_DASHBOARD`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          this.setState({ setUp: true });
        } else {
          this.setState({ setUp: false });
        }
      } else {
        this.setState({ setUp: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ branchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { branchOptions } = this.state;
    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      const { history } = this.props;

      token === undefined && history.replace("/login");
      if (Cookies.get("jwt_token") !== undefined) {
        this.getBatchOptions();
        this.getData();
        this.getBranchOptions();
      }

      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        claim["user_type"] !== "PRINCIPAL" &&
          claim["user_type"] !== "HOD" &&
          history.replace("/");
        this.setState({ claims: claim });
      }
    }
  }

  renderAccount = () => {
    const { hodCreated, facData, hodsList, isLoading, hodDataLoading } =
      this.state;

    return (
      <div className={styles["account-container"]}>
        {hodCreated ? (
          <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <img
              src="/assets/tick.svg"
              alt="new course"
              width={34}
              height={34}
            />
            <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
              HoD created successfully!
            </h1>
            <button
              onClick={this.okBtn}
              className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
              type="button"
            >
              Ok
            </button>
          </div>
        ) : (
          <form
            onSubmit={this.addHod}
            className={`${styles["info-container"]} flex flex-col`}
          >
            <p className={styles["hod-main-heading"]}>Create HOD Account</p>
            <span className="flex items-center mb-5">
              <span className="mr-12">
                <p className={styles["label"]}>HOD ID</p>
                <input
                  required
                  value={facData["facultyUsername"]}
                  onChange={this.facultyUserNameChanged}
                  type="text"
                  placeholder="Ex : VCEHODIT"
                  className={styles["info-input"]}
                />
              </span>

              <span>
                <p className={styles["label"]}>Name</p>
                <input
                  required
                  value={facData["facultyName"]}
                  onChange={this.facultyNameChanged}
                  type="text"
                  placeholder="Ex : Raju"
                  className={styles["info-input"]}
                />
              </span>
            </span>

            <span className="flex items-center mb-5">
              <span className="mr-12">
                <p className={styles["label"]}>Department</p>
                <select
                  required
                  value={facData["deptName"]}
                  onChange={this.deptChanged}
                  className={styles["filter-select"]}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>

                  {this.renderBranchOptions()}
                </select>
              </span>

              <span>
                <p className={styles["label"]}>Login Password</p>
                <input
                  required
                  value={facData["loginPassword"]}
                  onChange={this.pwdChanged}
                  type="password"
                  placeholder="Enter"
                  className={styles["info-input"]}
                />
              </span>
            </span>

            <span className="flex items-center mb-5">
              <span className="mr-12">
                <p className={styles["label"]}>Email Id</p>
                <input
                  required
                  value={facData["workEmail"]}
                  onChange={this.mailChanged}
                  type="text"
                  placeholder="Enter"
                  className={styles["info-input"]}
                />
              </span>

              <span className="flex items-center self-end justify-center mr-4 mb-2">
                <input
                  id="freshman"
                  type="checkbox"
                  className={`${styles["sec-check"]} mr-2`}
                />
                <label
                  htmlFor="freshman"
                  className={`${styles["sec-check-label"]} mt-1`}
                >
                  Freshman Engineering
                </label>
              </span>

              <span className="self-end ml-auto mr-10">
                <button
                  disabled={isLoading}
                  type="submit"
                  className={`${styles["insert-btn"]} ${
                    isLoading &&
                    "cursor-not-allowed flex justify-center items-center"
                  }`}
                >
                  {isLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="#FFFFFF"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#FFF"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    "Create HOD Account"
                  )}
                </button>
              </span>
            </span>
          </form>
        )}

        <div className={`${styles["info-container"]} flex flex-col mt-6`}>
          <p className={styles["hod-main-heading"]}>Existing HOD Accounts</p>

          <div className="font-Lato-normal font-bold text-base flex flex-row text-[#8F949F] mb-5 ">
            <p
              className={`${styles["table-labels"]} w-3/12 md:mr-4 flex flex-row justify-start`}
            >
              HOD ID
            </p>
            <p
              className={`${styles["table-labels"]} w-1/4 md:w-2/12 md:mr-4 flex flex-row justify-start`}
            >
              Name
            </p>
            <p
              className={`${styles["table-labels"]} w-2/12 ml-12 xl:ml-16 flex flex-row justify-start`}
            >
              Branch
            </p>
            <p
              className={`${styles["table-labels"]} w-2/12 ml-4 xl:ml-8 flex flex-row justify-start`}
            >
              Contact Info
            </p>
          </div>

          <ul>
            {hodDataLoading ? (
              <div className="flex justify-center mt-10">
                <Oval
                  height={50}
                  width={50}
                  color="#3d65f4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#3d65f4"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              hodsList.map((each) => (
                <FacultyList
                  data={each}
                  facDel={this.facDel}
                  key={each["facultyUsername"]}
                />
              ))
            )}
          </ul>
        </div>
      </div>
    );
  };

  renderView = () => {
    const { setUp } = this.state;
    try {
      switch (setUp) {
        case true:
          return this.renderAccount();
        case false:
          return <InitialSetup />;
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { match } = this.props;
    const { path } = match;
    const { claims } = this.state;

    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div
          className={`${styles["home"]} flex flex-row overflow-hidden bg-[#FFFFFF]`}
        >
          <CampusNavBar />
          <div className="flex justify-between w-[100%] h-screen">
            <span className="w-[12%]">
              <SideBar />
            </span>
            <div className="bg-[#fff] w-[88%] relative h-screen overflow-hidden overflow-y-auto">
              <NavLink path={path} claims={claims} />

              {this.renderView()}
            </div>
          </div>
        </div>
      </span>
    );
  }
}

export default ManageHods;
