import styles from "./index.module.css";
import { Link, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import * as jose from "jose";

const ConnectNavBar = (props) => {
  const { match, history } = props;
  const { path } = match;

  const [popActive, updatePop] = useState(false);

  const popBtn = () => {
    updatePop(!popActive);
  };

  const [claims, updateClaims] = useState({});

  useEffect(() => {
    const token = Cookies.get("jwt_token");

    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateClaims(claim);
    }
  }, []);

  const logoutClicked = () => {
    const { history } = props;

    Cookies.remove("jwt_token");
    history.replace("/login");
  };

  document.addEventListener("click", (e) => {
    if (e.target.id === "box") {
      if (popActive === false) {
        updatePop(true);
      }
    } else {
      updatePop(false);
    }
  });

  return (
    <nav
      className={`${styles["nav-bar"]} flex flex-row justify-between items-center`}
    >
      <div
        className={`${styles["rubrix-logo-container"]} flex flex-row justify-between items-center`}
      >
        <img
          src="/assets/logo.svg"
          alt="Fatcat"
          className={styles["rubrix-icon"]}
        />
        <img
          src="/assets/fatcatTitle.svg"
          alt="Fatcat"
          className={styles["fatcat-title"]}
        />
      </div>

      <div className="flex justify-between items-center w-[100%]">
        <span className="flex flex-row items-center w-[50%]">
          <ul
            className={`${styles["tab-bar"]} flex flex-row justify-around items-center bg-[#ECF0FF] list-style-none`}
          >
            <li
              className={`hover:text-[#3f4451] ${
                path.startsWith("/connect/dashboard")
                  ? "text-[#3D65F4] font-black"
                  : "text-[#606981]"
              }`}
            >
              <Link to="/connect/dashboard">Dashboard</Link>
            </li>
            <li
              className={`hover:text-[#3f4451] ${
                path.startsWith("/connect/kanban")
                  ? "text-[#3D65F4] font-black"
                  : "text-[#606981]"
              }`}
            >
              <Link to="/connect/kanban">Kanban</Link>
            </li>

            <li
              className={`hover:text-[#3f4451] ${
                path.startsWith("/connect/info")
                  ? "text-[#3D65F4] font-black"
                  : "text-[#606981]"
              }`}
            >
              <Link to="/connect/info">Info</Link>
            </li>
          </ul>

          <div
            className={`${styles["arrows-container"]} flex flex-row justify-between items-center ml-10`}
          >
            <div
              onClick={() => history.goBack()}
              className={`${styles["arrow-bg"]} cursor-pointer`}
            >
              <img
                src="/assets/leftarrow.svg"
                width={9}
                height={9}
                alt="leftarrow"
              />
            </div>
            <div
              onClick={() => history.goForward()}
              className={`${styles["arrow-bg"]} cursor-pointer`}
            >
              <img
                src="/assets/rightarrow.svg"
                width={9}
                height={9}
                alt="rightarrow"
              />
            </div>
          </div>
        </span>

        <span className="flex flex-row justify-between items-center w-[15%] xl:w-[12%] mr-4 xl:mr-6">
          <div className="ml-5">
            <span className="cursor-not-allowed mr-8 flex items-center">
              <span
                className={`${styles["bell-icon"]} flex flex-col items-center`}
              >
                <img
                  src="/assets/notification.svg"
                  width={20}
                  height={20}
                  alt="notifications"
                />
                <img
                  src="/assets/bellbottom.svg"
                  width={8}
                  height={8}
                  alt="notifications"
                />
              </span>
              <span
                className={`${styles["count"]} flex items-center text-white bg-orange-700 absolute border-2 border-white mb-2 ml-4 px-1 justify-center`}
              >
                0
              </span>
            </span>
          </div>

          <div
            className={`${styles["profile"]} ml-3 cursor-pointer`}
            onClick={popBtn}
          >
            <img
              id="box"
              src="/assets/defaultImage.svg"
              className={`rounded-full`}
              width={45}
              height={45}
              alt="profile"
            />
            {popActive && (
              <span className={`${styles["dropdown"]}`}>
                <ul className="flex flex-col p-5 list-none">
                  {claims["user_type"] === "FACULTY" ? (
                    <Link to="/faculty/account">
                      <li className={`${styles["li-items"]}`}>
                        <img
                          src="/assets/myAccount.svg"
                          width={18}
                          height={18}
                          alt="Account"
                        />
                        <span className={`${styles["li-text"]}`}>
                          My Account
                        </span>
                      </li>
                    </Link>
                  ) : (
                    <Link to="/obe/account">
                      <li className={`${styles["li-items"]}`}>
                        <img
                          src="/assets/myAccount.svg"
                          width={18}
                          height={18}
                          alt="Account"
                        />
                        <span className={`${styles["li-text"]}`}>
                          My Account
                        </span>
                      </li>
                    </Link>
                  )}
                  {claims["user_type"] === "PRINCIPAL" && (
                    <>
                      <Link to="/obe/hods">
                        <li className={`${styles["li-items"]}`}>
                          <img
                            src="/assets/hods.svg"
                            width={18}
                            height={18}
                            alt="Hods"
                          />
                          <span className={`${styles["li-text"]}`}>
                            Manage HODs
                          </span>
                        </li>
                      </Link>
                    </>
                  )}
                  {claims["user_type"] !== "FACULTY" && (
                    <Link to="/obe/campus-setup">
                      <li className={`${styles["li-items"]}`}>
                        <img
                          src="/assets/campusSetup.svg"
                          width={18}
                          height={18}
                          alt="Campus Setup"
                        />
                        <span className={`${styles["li-text"]}`}>
                          Manage Campus Setup
                        </span>
                      </li>
                    </Link>
                  )}
                </ul>
                <button
                  onClick={logoutClicked}
                  className={`${styles["logout-btn"]} ml-6`}
                >
                  Logout
                </button>
              </span>
            )}
          </div>
        </span>
      </div>
    </nav>
  );
};

export default withRouter(ConnectNavBar);
