import styles from "./index.module.css";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting");

const WeeklyProgress = (props) => {
  const options = {
    chart: { width: 300, height: 250 },
    title: {
      text: "75%",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#33AC72",
        fontFamily: "Poppins",
        fontWeight: "600",
        fontSize: "32px",
      },
    },

    subtitle: {
      text: "Tasks Completed",
      verticalAlign: "middle",
      y: 40,
      style: {
        color: "#121212",
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "12px",
      },
    },

    legend: {
      enabled: false,
    },

    credits: { enabled: false },

    tooltip: {
      valueDecimals: 0,
      valueSuffix: "",
    },

    plotOptions: {
      pie: { startAngle: 180 },
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "85%",
        dataLabels: {
          enabled: false,
        },
      },
    },

    colors: ["#46BD84", "#ECF3FE"],
    series: [
      {
        type: "pie",
        name: "",
        data: [
          ["Completed", 75],
          ["Incomplete", 25],
        ],
      },
    ],
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>Coming Soon</p>
    </div>
  );

  return (
    <div className={styles["weekly-progress-card"]}>
      <h1>Weekly Progress</h1>
      {true ? (
        renderEmptyView()
      ) : (
        <>
          <p>Start from Nov 7-14, 2020</p>
          <img
            src="/assets/dots.svg"
            alt="dots"
            className="rotate-90 absolute top-5 right-8"
          />

          <div className="mt-16">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              {...props}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default WeeklyProgress;
